.address-page {
  .submit-btn {
    border: 2px solid #1B4170;
    --ion-color-base: #ffffff !important;
    border-radius: 8px;
  }

  .address-footer-container {
    padding: 8px;
  }
}
