.wallet-withdrawal-form {
  padding: 16px;
  height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .wallet-withdrawal-form__title {
    font-size: 18px;

    & > img {
      height: 20px;
      margin: 0 8px -4px 8px;
    }
  }

  .wallet-withdrawal-form__balance {
    font-size: 15px;
    font-weight: 300;
    margin: 8px 0 16px 0;

    & > span {
      color: var(--ion-color-secondary);
      margin-left: 16px;
      font-weight: 500;
    }
  }

  & > ion-label {
    font-size: 18px;
  }

  .withdrawal-remark__label--grey {
    font-size: 18px;
    margin-bottom: 8px;
    display: block;
  }

  .withdrawal-remark--grey {
    margin: 0 0 20px 0;
    font-size: 15px;
    font-weight: 300;
  }

  .withdrawal-remark--red {
    margin: 0 0 16px 0;
    color: #D0021B;
    font-size: 18px;
    font-weight: 500;
  }
}
