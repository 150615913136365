ul {
  padding: 0;
  margin: 0;

  .chip-container { 
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 8px;
  }

  li {
    position: relative;
    list-style: none;
    display: flex;
    transition: 0.5s all;

    &.container-provider {
      justify-content: flex-end;
    }

    &:after {
      display: table;
      content: '';
      clear: both;
    }

    .conversation-timstamp {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-end;
      margin: 0 0 1rem 0;

      p {
        font-size: 12px;
        font-weight: 300;
        margin: 0;
        color: #B6B6B6;
      }
    }

    .conversation-content {
      margin: 0 0 1rem 0;
      max-width: 85%;
      display: inline-block;
      padding: 0.7rem 1rem;
      line-height: 1rem;
      min-height: 2rem;
      font-size: 14px;
      border-radius: 5px;
      word-break: break-all;

      p {
        font-size: 1rem;
        margin: 0;
      }

      img {
        max-height: 200px;
      }

      &.customer {
        float: left;
        margin-right: 8px;
        border: 1px solid #E5E5E5;
        background-color: #E5E5E5;
        color: #000000;
      }

      &.provider {
        float: right;
        margin-left: 8px;
        border: 1px solid var(--ion-color-primary);
        background-color: var(--ion-color-primary);
        color: #FFFFFF;
      }
    }
  }
}
