.input-container {
  border-radius: 5px;
  --padding-start: 16px;
  z-index: 0;
}

.input-container--basic {
  border: 1px solid var(--ion-color-medium);
}

.input-container--error {
  border: 1px solid var(--ion-color-danger);
}

ion-item {
  &.item-interactive-disabled {
    --ion-item-background: #f4f4f4;
  }
}
