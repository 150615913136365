.verify-grid {
  margin: 0 20px;

  h4 {
    margin: 0;
  }
}

.verify-logo-row {
  margin-top: 12px;
  margin-bottom: 16px;
}

.verify-subheader-row {
  margin-bottom: 12px;
}

.verify-error {
  color: #ed3138;
}

.verify-phone-number-row {
  margin-bottom: 24px;
}
.verify-phone-number-form-row {
  margin-bottom: 12px;
}

.verify-phone-number-error-row {
  margin-bottom: 6px;
}
