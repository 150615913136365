.static-map-container {
  position: relative;
  box-shadow: 0 1px 3px 0 #b6b6b6;

  .static-map-expand-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}
