.policy-terms-modal {
  z-index: 20010 !important;

  .policy-modal-header {
    .title {
      padding: 30px 0;
    }

    ion-toolbar {
      &.first-bar{
        --border-width: 0 0 0.55px;
        --min-height: 70px;
        ion-title {
          padding-inline: 50px;
          // padding-inline-start: 50px;
          // padding-inline-end: 50px;
        }
        .back-button {
          padding-left: 20px;
        }
      }

      &.second-bar {
        & > div {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 15px 0;
          @media only screen and (max-width: 376px) {
            padding: 10px 15px;
            font-size: small;
          }
        }

        .language-select {
          border: 1px solid #c2c6ca;
          border-radius: 25px;
          padding: 5px 10px;
        }
      }
    }
  }

  .policy-modal-content {
    padding: 10px 24px;
    @media only screen and (max-width: 376px) {
      padding: 10px 15px;

    }
  }

  .policy-modal-footer {
    ion-toolbar {
      .buttons {
        display: flex;
        justify-content: space-evenly;
        padding: 10px 24px;

        .contact-button{
          flex: 1;
        }
      }
    }
  }

}

