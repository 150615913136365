@import 'src/theme/variables.scss';

.job-question-answer-container {
  margin-top: 14px;

  .job-text-question {
    display: block;
  }

  .job-text-answer {
    font-weight: $light;
    white-space: break-spaces;
  }

  .job-image-answer {
    width: calc((100% - 24px) / 3);
    height: 100px;
    display: inline-block;
    margin-top: 8px;
    border-radius: 6px;
    background-color: var(--ion-color-light);

    & > * {
      border-radius: 5px;
    }
  }

  .job-images-answer-container {
    & > *:nth-child(3n+2) {
      margin-left: 12px;
      margin-right: 12px;
    }
  }
}
