.with-safe-area-top {
  padding-top: var(--ion-safe-area-top);
  background-color: var(--ion-color-primary);
}

.with-safe-area-bottom {
  padding-bottom: env(--ion-safe-area-bottom);
}

.no-background {
  background: none;
}
