.verify-info-grid {
  margin: 0 20px;
  p,
  h3 {
    margin: 0;
  }
  .verify-info-logo {
    margin-top: 18px;
    margin-bottom: 12px;
  }
  .verify-info-header {
    margin-bottom: 12px;
  }

  .verify-info-subheader {
    margin-bottom: 16px;
    margin: 0;
  }
  .verify-info-paragraph {
    margin-bottom: 30px;
  }
  .verify-info-picture-row {
    .avatar-row {
      margin-bottom: 16px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    .verify-info-picture {
      height: 150px;
      width: 170px;
    }
    margin-bottom: 40px;
  }
  .verify-info-form {
    transition: width 2s, height 2s, transform 2s;
  }
  .verify-info-form-hidden {
    display: none;
  }
}
