@import 'src/theme/variables.scss';

.region-select-field-label, .services-select-field-label {
  display: block;
  margin-bottom: 6px;
}

.category-name-label {
  font-size: $caption !important;
  font-weight: $light;
}

.region-select-container {
  border: 1px solid var(--ion-color-medium);
  border-radius: 5px;
  padding-right: 12px;
  margin-bottom: 24px;
}

.service-list-container {
  display: flex;
  flex-wrap: wrap;

  .service-card-container {
    width: calc((100% - 36px) / 3);
    margin: 10px 6px;

    --padding-start: 0;
    --inner-padding-end: 0;
    --background-activated: transparent;
    --background-hover: transparent;

    .service-card-thumbnail {
      width: 100%;
      height: 90px;

      .service-card-img {
        border-radius: 6px;
        border: 3px solid var(--ion-color-light-tint);
      }

      .service-card-img--selected {
        border: 3px solid var(--ion-color-secondary-tint);
      }
    }

    .service-name-label {
      white-space: normal;
    }
  }
}
