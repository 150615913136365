.job-delivery-container {
  background-color: #1B4170;
  padding-top: 0;
}

.content-col-center {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100px;
}

.content-col-center-active {
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100px;
}

.address-delivery-card {
  z-index: 1;
  position: absolute;
  top: 25px;
  padding: 16px;

  .hydrated {
    padding: 0;
  }
}

.direction-image {
  width: 18px;
}

.direction-button {
  margin: 0;
  margin-left: -24px;
  padding-bottom: 16px !important;
}

.details-button {
  margin: 0;
  padding: 8px 0 !important;
}

.next-icon {
  height: 30px;
}

.address-delivery-card-header {
  padding: 0;
}