@import 'src/theme/variables.scss';

.page-content {
  background-color: #fff;
  --padding-start: 16px;
  --padding-end: 16px;

  .page-content__pending-approve-img {
    display: block;
    margin: 16px auto;
  }

  .bank-select__bank-detail {
    --padding-start: 0;

    .bank-select__bank-logo-url {
      max-width: 50px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 9px;
    }

    ion-label {
      h3 {
        font-size: $body;
        color: var(--ion-color-secondary);
        line-height: 25px;
        margin: 0;
      }

      h4 {
        font-size: $subtitle;
        line-height: 25px;
      }
    }
  }

  h5 {
    text-align: center;
    margin: 0;
    padding-bottom: 16px;
  }

  p {
    margin: 0;
  }

}
