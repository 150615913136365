.sign-up-referral-complete-container {
  height: 100%;
  .sign-up-referral-complete-content-container {
    background: white;
    padding-top: 100px;
    height: 100%;
  }
  .sign-up-referral-complete-success-image-row {
    justify-content: center;
    margin-bottom: 32px;

    .sign-up-referral-complete-pending-approve-img {
      max-width: 60px;
      max-height: 60px;
    }
  }
  
  .sign-up-referral-complete-success-text-row {
    justify-content: center;
    margin-left: 16px;
    margin-right: 16px;


    .sign-up-referral-complete-success-text {
      color: #47525D;
      font-size: 20px;
      font-weight: 500;
      text-align: center;
    }
  }
  .sign-up-referral-complete-success-text-row-name {
    justify-content: center;
    margin-left: 16px;
    margin-right: 16px;
  } 
  .sign-up-referral-complete-success-name-text {
    color: #47525D;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
  }
    .sign-up-referral-complete-name-text {
      color: #47525D;
      font-size: 20px;
      font-weight: 500;
      margin-top: 0px;

    }

  .sign-up-referral-complete-content-row {
    margin-top: 26px;
    margin-left: 26px;
    margin-right: 26px;
    margin-bottom: 16px;
    justify-content: center;
    .sign-up-referral-complete-content-text {
      font-size: 16px;
    }
  }

  .sign-up-referral-complete-social-row {
    justify-content: center;
    margin-bottom: 100px;


  .sign-up-referral-complete-line-logo-container {
    background: #00C300;
    max-height: 32px;
    max-width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-right: 7px;
    img {
      width: 100%;
      height: 100%
    }
  }
  .sign-up-referral-complete-line-text {
    font-size: 16px;
    color: #47525D;
    }
  }
  .sign-up-referral-download-app-text-row {
    justify-content: center;
    margin-bottom: 16px;
    .sign-up-referral-download-app-text {
      font-size: 16px;
    }
  }

  .sign-up-referral-download-container {
    width: 100%;
    justify-content: center;
  }

  .sign-up-referral-download-app {
    height: 40px;
    width: 134px;
  }

}
.sign-up-referral-complete-line-link {
  text-decoration: none;
}
.abc {
  justify-content: center;
}
