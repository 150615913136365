.team-people-page {
  .team-people-page___header {
    // position: relative;
    // background-color: var(--ion-color-primary);

    ion-grid {
      ion-row.avatar-row {

      }
    }
  }

  .team-people-page___sub-header {
    ion-avatar {
      width: 72px;
      height: 72px;
    }
  }
}