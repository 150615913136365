.switch-tenant-page {
  padding: 24px 16px 16px 16px;

  .switch-tenant-button {
    margin-bottom: 16px;
  }

  .button-divider {
    display: flex;
    padding: 0 8px;
    margin: 16px 0;

    .text {
      margin: 0 10px;
    }

    .line {
      flex: 30%;
      border-bottom: 1px solid black;
      transform: translateY(-45%);
    }
  }
}
