.bank-account-card-contanier {
  .row-flex-end {
    justify-content: flex-end;
    padding-top: 11px;
  }

  .bank-account-text-container {
    padding-bottom: 16px;
  }

  .bank-account-top-container {
    padding-bottom: 16px;
    padding-top: 11px;
  }

  .bank-account-bottom-container {
    padding-bottom: 11px;
  }
  
  .bank-logo {
    width: 25px;
    height: 25px;
    margin-left: 8px;
    margin-right: 8px;
  }

  .bank-edit-button {
    --padding-bottom: 16px;
    --padding-end: 16px;
    --padding-start: 16px;
    --padding-top: 16px;
    --color : #47525D;
  }
}