.otp-input {
  width: 100%;
  caret-color: transparent;
  text-align: center;
  font-size: 30px;
  border: 0px;
  border-radius: 0px !important;
  border-bottom: 2px solid black !important;
  .has-focus {
    border-bottom: 2px solid #2d95f4;
  }
}

.error {
  border-bottom: 2px solid #ed3138;
}
