.line-login-button {
  --background: #00C300;
  --background-activated: #00B300;
  --background-focused: #00E000;
  --background-hover: #00E000;
  --padding-start: 0px;
  --padding-end: 30px;

  height: 40px;
  margin-bottom: 16px;

  .line-login-button__icon {
    height: 100%;
    border-right: 1px solid #00B300;
  }

  a {
    color: white;
    text-decoration: none;
  }
  a :visited {
    color: white;
    text-decoration: none;
  }

  &:hover .line-login-button__icon {
    border-right: 1px solid #00C900;
  }

  .line-login-button__text {
    width: 100%;
  }
}
