@import 'src/theme/variables.scss';

.conversation-list {
  .conversation-list__item {
    --padding-top: 1rem;
    --padding-bottom: 1rem;
    --padding-start: 1rem;
    --padding-end: 1rem;
    --inner-padding-end: 0;

    .conversation-list__img {
      width: 44px;
      height: 44px;
      border-radius: 50%;
    }

    .conversation-list__label {
      margin: 0 0 0 1rem;

      p {
        margin: 0;
        font-size: $subtitle;
        color: var(--ion-text-color);
      }
      
      span {
        font-size: $caption;
      }
    }
  }
}
