.date-range-picker-label {
  padding-bottom: 8px;
}

.DateRangePickerInput {
  input {
    text-align: center;
  }
}

.DateRangePicker_picker {
  z-index: 5;
}

.DateRangePickerInput input {
  font-size: 16px;
}

.DateRangePickerInput_arrow {
  line-height: 5px;
}
