@import 'src/theme/variables.scss';

.setting-page-header {
  position: relative;
  background-color: var(--ion-color-primary);
  margin-bottom: 71px;

  .setting-page-header__provider {
    --background: transparent;
    padding: 8px 8px 58px 8px;

    .setting-page-header__avatar-container {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: center;
    }

    .setting-page-header__provider__avatar {
      width: 57px;
      height: 57px;
    }

    .setting-page-header__provider__name {
      font-size: $title2;
    }

    .setting-page-header__provider__rating {
      font-size: 15px;
    }

    .setting-page-header__provider__point {
      font-size: 18px;
    }
  }

  .setting-page-row__provider__rating {
    margin-bottom: -16px;
  }

  .icon-rating {
    height: 21px;
    margin: 0 6px 0 12px;
  }

  .wallet-balance-card {
    position: absolute;
    bottom: -55px;
    height: 111px;
    margin: 0 8px;
    width: -webkit-fill-available;
    --box-shadow: 0px 3px 5px 0px rgba(82,82,82,0.5);

    .wallet-balance-card__content-wrapper {
      width: 100%;
      --background: transparent;
      --padding-start: 0px;

      .wallet-balance-card__label {
        display: block;
        font-weight: $regular;
        margin-bottom: 16px;
      }

      .wallet-balance-card__amount {
        display: block;
        font-size: $title1;
      }

      .wallet-balance-card__icon {
        height: 31px;
      }
    }
  }
}

.setting-menu-list {
  margin-bottom: 16px;
}

.profile-icon-image {
  width: 17px;
  margin: 0 20px 0 4px;
}

.address-icon-image {
  width: 17px;
  margin: 0 20px 0 4px;
}

.wallet-icon-image, .contact-icon-image {
  width: 22px;
  margin: 0 17px 0 2px;
}

.setting-new-item-label {
  max-width: max-content;
  font-weight: $light;
  margin: 16px 0 16px 0;
  padding: 0;
}

.setting-item-label {
  font-weight: $light;
  margin: 16px 0 16px 0;
}

.setting-item-label-text-right {
  text-align: right;
}

.setting-item-label-right {
  display: flex;
  text-align: right;
  align-items: center;
  justify-content: flex-end;
}

.new-badge {
  margin: 0 6px;
  padding: 4px 12px;
  font-weight: 500;
}

.change-language-icon, .refer-friend-icon {
  color: #808080;
}

.logout-button {
  text-align: center;
  --color: var(--ion-color-danger) !important;
}

.workforce-small-logo {
  width: 100px;
  margin: 4px auto 8px auto;
}

.app-version {
  display: block;
  text-align: center;
  font-size: $caption;
}

.setting-page {
  &.toast {
    --background: var(--ion-color-secondary, #3171e0);
    --color: var(--ion-color-secondary-contrast, #fff);
  }

  &.bottom-sheet {
    // top: 40%;
    .modal-wrapper {
      transform: translate3d(0, 40%, 0);
    }
  }
}

ion-list.bottom-drawer {
  ion-item {
    --min-height: 70px;
  }
}

.privacy-icon, .terms-icon, .support-icon {
  fill: #808080;
  height: 23px;
}

.terms-icon {
  margin-left: 4px;
  margin-right: 16px;
}