.chat-form {
  display: flex;

  .file-upload {
    color: #B8B7B7;

    input[type=file] {
      display: none;
    }
  }

  .input-conatiner  {
    width: calc(100vw - 64px);
    margin: 6px 0.5rem;

    .error-message-placeholder {
      display: none;
    }

    textarea {
      display: block;
      padding: 0.5rem 0;
    }
  }

  label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    float: right;

    &.active {
      color: var(--ion-color-primary);
    }

    &.inactive {
      color: var(--ion-color-medium);
    }
  }
}
