.sign-in-with-phone-number-form-container {
  .phone-number-input {
    flex-grow: 1;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    border-radius: 0px 5px 0px 0px !important;
    font-size: 18px !important;
    height: 100% !important;
    font-family: kanit;
    padding-left: 10px !important;
  }

  .password-input {
    margin-bottom: 8px;

    .password-field {
      font-family: kanit;
    }
  }

  ion-item {
    border-radius: 0px 0px 5px 5px;
    border-left: 1px solid rgba(0, 0, 0, 0.2) !important;
    border-right: 1px solid rgba(0, 0, 0, 0.2) !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
    border-top: 0px;
  }

  .send-otp-small-paragraph-1 {
    margin-bottom: 12px;
  }

  .send-otp-small-paragraph-2 {
    margin-bottom: 12px;
    .link {
      color: #2d95f4;
      text-decoration: underline;
    }
  }

  .input-container {
    display: flex;
    height: 53px;
  }

  .country-box-login {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    height: 100%;

    border-top-left-radius: 5px;

    img {
      margin-left: 10px;
      margin-right: 10px;
      height: 25px;
    }

    .country-number {
      margin-right: 5px;
    }
  }
}
