/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

// Import for Roboto Font
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('/assets/fonts/Kanit-Light.woff2') format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('/assets/fonts/Kanit-Light-Latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/assets/fonts/Kanit-Regular.woff2') format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/assets/fonts/Kanit-Regular-Latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('/assets/fonts/Kanit-Medium.woff2') format('woff2');
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('/assets/fonts/Kanit-Medium-Latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC,
    U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/** Ionic CSS Variables **/
:root {
  --ion-font-family: Kanit, 'Helvetica Neue', 'Roboto', sans-serif;
  --ion-text-color: #47525d;

  /** primary **/
  --ion-color-primary: #1b4170;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #183963;
  --ion-color-primary-tint: #32547e;

  /** secondary **/
  --ion-color-secondary: #5b8ac3;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #5079ac;
  --ion-color-secondary-tint: #6b96c9;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #18cb88;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #15b378;
  --ion-color-success-tint: #2fd094;

  /** warning **/
  --ion-color-warning: #ff651f;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0591b;
  --ion-color-warning-tint: #ff7435;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #1b4170;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

ion-button {
  --border-radius: 5px;
}

ion-tab-bar {
  ion-tab-button.md {
    @media only screen and (max-width: 376px) {
      font-size: 10px;
    }
    @media only screen and (max-width: 347px) {
      font-size: 9px;
    }
    @media only screen and (max-width: 320px) {
      font-size: 9px;
      & ion-icon {
        font-size: 20px;
      }
    }
  }
}

.assign-provider-success-toast {
  top: 50%;
  transform: translateY(-50%);
}
