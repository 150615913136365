@import 'src/theme/variables.scss';

.wallet-withdrawal-error-page {
  .content {
    padding: calc((100vh - 56px - 118.8px) / 2) 0; 
    text-align: center;

    p {
      font-size: $body;
      line-height: 25px;
      margin: 0;
    }

    ion-button {
      --padding-top: 10px;
      --padding-bottom: 10px;
      --padding-start: 10px;
      --padding-end: 10px;
      margin: 24px 0 0 0;

      ion-text {
        font-size: $body;
        margin-left: 10px;
      }
    }
  }  
}
