.country-modal {
  .country-modal-header-section {
    .country-modal-input {
      margin-bottom: 12px;
      display: flex;
      justify-content: center;
      input {
        max-width: 80%;
        background-color: #acd1ff29;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        --padding-start: 12px;
        --padding-bottom: 12px;
        font-size: 14px;
      }
    }
  }

  .country-modal-content-section {
    .country-modal-list {
      margin: 0 20px;
    }
    .country-item {
      margin-bottom: 5px;

      .country-avatar {
        margin-right: 6px;
      }
      .country-name {
        font-size: 16px;
        display: flex;
        align-items: center;
      }

      .country-code {
        font-size: 16px;
        display: flex;
        align-items: center;
      }
    }
  }
}
