.assign-provider-page {
  ion-searchbar {
    // padding-top: 0;
    // --box-shadow:  0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    --box-shadow: none;
    --border-radius: 25px;
    padding-top: 25px;
    --border-width: 0;
    margin-bottom: 10px;
    .searchbar-input-container  {
      // --border: solid 1px var(--ion-color-primary);
      border: solid 1px var(--ion-color-primary);
      // -webkit-box-shadow: 0 8px 14px rgba(0, 0, 0, 0.1) !important;
      // box-shadow: 0 8px 14px rgba(0, 0, 0, 0.1) !important;
      border-radius: 25px;

      input.searchbar-input {
        padding-inline-start: 15px !important;
        background-color: white;
      }

      ion-icon {
        right: 16px !important;
        left: auto !important;
      }
    }



    .searchbar-clear-button.sc-ion-searchbar-md {
      right: 35px;
    }
  }

  .header-searchbar {
    --background-color: white;
  }

  .assign-provider-page___list {
    .assign-provider-page___item {
      font-size: medium;

      border: solid 1px #5A8AC3;
      border-radius: 5px;
      padding: 10px 5px;
      margin-bottom: 3px;

      display: flex;
      align-items: flex-start;

      .provider-detail {
        display: flex;
        flex-direction: column;

        .title {
          display: flex;
          justify-content: space-between;
          .rating {
            // margin-right: auto;
          }
        }
        .distance {
          display: flex;
          padding-top: 5px;
          font-size: small;
          ion-icon {
            font-size: large;
            margin-right: 5px;
          }
        }
      }

      .rating {
        margin-bottom: auto;
      }
    }
  }

}