ion-grid.team-job-card {
  font-size: medium;
  padding-left: 0px;
  ion-col {
    padding-left: 0px;
  }

  .team-job-card__first-row {
    ion-col.title {
      display: flex;
      padding-left: 0px;

      ion-thumbnail {
        margin-right: 8px;
      }

      ion-item {
        --inner-padding-end: 0px;
        width: 100%;
      }

      ion-chip {
        margin: 0;
      }
    }

    ion-col.status {
      display: flex;
      ion-button {
        --border-radius: 30px;
      }

      ion-chip {
        &.pending_start {
          background: #F1701B;
          color: white;
        }
        &.pending_complete {
          background: #18ABCC;
          color: white;
        }
        &.completed {
          background: #21BA45;
          color: white;
        }
        &.cancel {
          background: #D0021B;
          color: white;
        }
        &.pending_assign {
          background: #1B4170;
          color: white;
        }

      }
    }
  }

  .team-job-card__second-row {
    .job-start-time {
      display: flex;
      align-items: center;
      ion-icon {
        margin-right: 5px;
        min-width: 16px;
      }
    }
    .price {
      display: flex;
      justify-content: flex-end;
      font-size: x-large;
      font-weight: 500;
    }
  }

  .team-job-card__third-row {
    .order {
      display: flex;
      justify-content: space-between;
    }
  }

  .team-job-card__fourth-row {
    .location, .provider {
      display: flex;
      align-items: center;

      ion-icon {
        font-size: 20px;
        margin-right: 5px;
        min-width: 20px;
      }

      .display_name {
        text-decoration: underline;
      }
      .recipient {
        margin-left: auto;
      }

      &.pending_start {
        .recipient {
          color: #F1701B;
        }
      }

      &.pending_complete {
        .recipient {
          color: #18ABCC;
        }
      }

      &.completed {
        .recipient {
          color: #21BA45;
        }
      }

      &.cancel {
        ion-icon {
          color: #D0021B;
        }
        .display_name {
          text-decoration: none;
          color: #D0021B;
        }
      }

    }
  }

}