.wallet-page__page-header > ion-toolbar {
  --border-width: 0 !important;
}

.wallet-page-header__backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  background-color: var(--ion-color-primary);
}

.wallet-page-content__container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .wallet-page__balance-card {
    background-color: var(--ion-color-secondary);
    color: white;
    margin: 16px;
    padding: 16px 9px 12px 9px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);

    display: flex;
    flex-wrap: wrap;

    .wallet-page__available-balance,
    .wallet-page__total-balance {
      flex: 50%;
      text-align: center;

      & > label {
        font-size: 15px;
      }

      & > p {
        font-size: 26px;
        font-weight: 500;
        margin: 4px 0 0 0;
      }
    }

    .wallet-page__available-balance {
      border-right: 1px solid white;
    }

    .wallet-page__processing-balance {
      flex: 100%;
      margin-top: 16px;

      & > p {
        font-size: 18px;
        margin: 0;
      }

      & > label {
        font-size: 13px;
        color: #F8BE0C;
      }
    }

    .wallet-page__updated-at {
      flex: 100%;
      font-size: 11px;
      font-weight: 300;
      margin: 12px 0 0 0;
      text-align: center;
    }
  }
}
