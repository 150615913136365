@import 'src/theme/variables.scss';

.carlendar-page {
  background-color: #ffffff;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}

.my-calendar {
  padding: 0;
  background-color: #ffffff;
}

.remark-for-calendar {
  padding-left: 16px;
  color: #47525D;
  font-size: 15px;
  font-weight: 300;

  .icon-actived-date {
    margin-top: 3px;
    margin-right: 10px;
    color: #2D95F4;
  }

  .icon-selected-date {
    color: #F05F60;
    margin-top: 3px;
    margin-right: 10px;
  }

  .icon-already-date {
    color: #18CB88;
    margin-top: 3px;
    margin-right: 10px;
  }
}

.full-background-block {
  width: calc(100% + 2rem);
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: 10;
}

.full-spot-light {
  background-color: black;
  width: calc(100% + 2rem);
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: 11;
  opacity: 0.9;
}

.text-full-spot-light {
  top: 20vh;
  margin-left: auto;
  margin-right: auto;
  left: 10%;
  right: 10%;
  position: absolute;
  z-index: 11;
  color: #FF9F00;
  
  h3 {
    font-size: 26px;
  }
}

.text-full-spot-light-title {
  top: 29vh;
  margin-left: auto;
  margin-right: auto;
  left: 10%;
  right: 10%;
  position: absolute;
  z-index: 11;
  color: #FFFFFF;
  font-size: 20px;
}

.selected-logo {
  width: 170px;
  top: 47vh;
  margin-left: auto;
  margin-right: auto;
  left: 10%;
  right: 10%;
  position: absolute;
  z-index: 11;
  color: #FFFFFF;
}

.text-full-spot-light-footer {
  top: 68vh;
  margin-left: auto;
  margin-right: auto;
  right: 0;
  left: 0;
  text-align: center;
  position: absolute;
  z-index: 11;
  color: #FFFFFF;
}

.bank-edit-button {
  margin: 0px 35%;
}

.Calendar__day.-ltr.-today.disabled_provider_date {
  background-color: #F05F60;
  color: #ffffff !important;
  border: #F05F60 solid 1px;
  border-radius: 50%;
}

.Calendar__day.-ltr.disabled_provider_date {
  background-color: #F05F60;
  color: #ffffff;
  border: #F05F60 solid 1px;
  border-radius: 50%;
}

.Calendar__day.-ltr.disabled_provider_date.-selected {
  background-color: #F84B60 !important;
  color: #ffffff !important;
  border: #F84B60 solid 1px !important;
}

.disabled-calendar {
  .Calendar__day.-ltr.-selected {
    background-color: #F84B60 !important;
    color: #ffffff !important;
    border: #F84B60 solid 1px !important;
    // background-image: none !important;
  }
}

.content-calendar-page {
  p {
    font-size: 18px;
  }

  p, h5 {
    padding: 0 16px;
    font-weight: 400;
  }
}

.job-time {
  padding: 0 0 16px 40px;
}

.job-time-calendar {
  padding: 0 16px 0 16px;
}

.header-selected-disabled-date {
  padding: 0 0 0 20px;
}

.Calendar {
  max-width: 100vw;
  width: 100%;
  padding-top: 0;
  box-shadow: none;
}

.Calendar__weekDays {
  padding: 0;
  color: rgba(0, 0, 0, 0.8);
  font-size: 1.45em;
}

.Calendar__header {
  background-color: #E9EFF5;
  margin-bottom: 8px;
}

.Calendar__monthText {
  color: #5B8AC3;
  font-size: 18px;
}

.Calendar__yearText {
  color: #5B8AC3;
  font-size: 18px;
}

.Calendar__monthArrow {
  color: #5B8AC3;
}

.Calendar__section.-shown {
  padding: 0;
}

.Calendar__day.-ltr {
  width: calc(100% / 10);
  margin: 16px 8px;
}

.Calendar__sectionWrapper {
  // height: 410px;
  height: 360px;
}

.Calendar__day.-ltr.-weekend {
  z-index: 2;
}

.Calendar__section.-hiddenNext {
  z-index: 1;
  margin-left: 26px;
}

.Calendar__day.-ltr.active_provider_date.-disabled {
  color: #CECECE !important;
}

.active_provider_date:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  color: #2D95F4;
}

.active_provider_date.-disabled:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected):hover {
  color: #CECECE !important;
}

.active_provider_date:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected):hover {
  color: #2D95F4 !important;
}

.Calendar__day.-ltr.disabled_provider_date:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
  background-color: #F05F60;
  color: #ffffff;
  border: #F05F60 solid 1px;
}

.Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
  background-color: #ffffff;
}

.Calendar__day.active_provider_date:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected):hover {
  background-color: #ffffff;
}

///

.group_available_active_disable_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background-image: url('/assets/img/dot-mix.png') !important;
  background-size: contain !important;
  background-position: 40% 100% !important;
}

.group_available_active_disable_day.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url('/assets/img/dot-mix.png') !important;
  background-size: contain !important;
  background-position: 40% 100% !important;
}

.pair_available_active_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background-image: url('/assets/img/dot-blue-green.png') !important;
  background-size: contain !important;
  background-position: 40% 100% !important;
}

.pair_available_active_day.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url('/assets/img/dot-blue-green.png') !important;
  background-size: contain !important;
  background-position: 40% 100% !important;
}

.pair_available_disable_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected){
  background-image: url('/assets/img/dot-red-green.png') !important;
  background-size: contain !important;
  background-position: 40% 100% !important;
}

.pair_available_disable_day.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url('/assets/img/dot-red-green.png') !important;
  background-size: contain !important;
  background-position: 40% 100% !important;
}

.pair_active_disable_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background-image: url('/assets/img/dot-blue-red.png') !important;
  background-size: contain !important;
  background-position: 40% 100% !important;
}

.pair_active_disable_day.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url('/assets/img/dot-blue-red.png') !important;
  background-size: contain !important;
  background-position: 40% 100% !important;
}

.pair_active_overlap_disable_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background-image: url('/assets/img/dot-blue-red.png') !important;
  background-size: contain !important;
  background-position: 40% 100% !important;
  text-decoration: line-through;
  color: #CECECE;
}

.pair_active_overlap_disable_day.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url('/assets/img/dot-blue-red.png') !important;
  background-size: contain !important;
  background-position: 40% 100% !important;
}

.pair_available_overlap_active_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background-image: url('/assets/img/dot-blue-green.png') !important;
  background-size: contain !important;
  background-position: 40% 100% !important;
  text-decoration: line-through;
  color: #CECECE;
}

.pair_available_overlap_active_day.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url('/assets/img/dot-blue-green.png') !important;
  background-size: contain !important;
  background-position: 40% 100% !important;
}

.group_available_active_overlap_disable_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background-image: url('/assets/img/dot-mix.png') !important;
  background-size: contain !important;
  background-position: 40% 100% !important;
  text-decoration: line-through;
  color: #CECECE;
}

.group_available_active_overlap_disable_day.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url('/assets/img/dot-mix.png') !important;
  background-size: contain !important;
  background-position: 40% 100% !important;
}

.active_day_overlab:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected){
  background-image: url('/assets/img/dot-blue.png') !important;
  background-size: contain !important;
  background-position: 40% 100% !important;
  text-decoration: line-through;
  color: #CECECE;
}

.active_day_overlab.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url('/assets/img/dot-blue.png') !important;
  background-size: contain !important;
  background-position: 40% 100% !important;
}

.active_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected){
  background-image: url('/assets/img/dot-blue.png') !important;
  background-size: contain !important;
  background-position: 40% 100% !important;
}

.active_day.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url('/assets/img/dot-blue.png') !important;
  background-size: contain !important;
  background-position: 40% 100% !important;
}

.available_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected){
  background-image: url('/assets/img/dot-green.png') !important;
  background-size: contain !important;
  background-position: 40% 100% !important;
}

.available_day.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url('/assets/img/dot-green.png') !important;
  background-size: contain !important;
  background-position: 40% 100% !important;
}

.disable_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected){
  background-image: url('/assets/img/red-dot.png') !important;
  background-size: contain !important;
  background-position: 40% 100% !important;
}

.disable_day.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url('/assets/img/red-dot.png') !important;
  background-size: contain !important;
  background-position: 40% 100% !important;
}


///
.Calendar__day.-ltr.disabled_provider_date.-disabled {
  background-color: #F05F60 !important;
  border: #F05F60 solid 1px;
  border-radius: 50%;
  color: #CECECE !important;
}

.calendar-main-page {
  background: #ffffff;
}

.alert-calendar {
  .alert-message {
    font-size: 15px;
    text-align: unset;

  }

  .alert-title {
    font-size: 18px;
    font-weight: 400;
  }

  .alert-head {
    text-align: unset;
    padding-bottom: 16px;
  }
}

.calendar-card-row {
  padding: 0 6px;
}

.modal-toolbar {
  --min-height: 56px;

  .page-title {
    font-size: $title2;
    font-weight: $regular;
    padding-inline-start: 0px;
    padding-inline-end: 0px;
  }
}

.child {
  padding-bottom: 16px;
}

.text-toast-button {
  position: absolute;
  right: 68px;
  width: 96px;
  color: #FFFFFF;
}

.text-toast-button-secondary {
  position: absolute;
  right: 53px;
  width: 96px;
  color: #ffffff;
}

.calendar-devide {
  box-sizing: border-box;
  height: 1px;
  width: 100%;
  margin-bottom: 16px;
  border: 1px dashed #CECECE;
}