.avatar-card {
  .avatar-name-text {
    font-size: 13pt;
    margin-left: 12px;
  }
  .avatar-img-container {
    height: 50px;
    width: 50px;
  }
}
