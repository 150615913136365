.item-no-padding {
  --padding-start: 0;
  --inner-padding-end: 0;

  & > * {
    margin: 0;

    & > * {
      margin: 0;
      height: 44px;
      font-size: 17px;
    }
  }

  & > *:first-child {
    margin-right: 8px;
  }

  & > *:last-child {
    margin-left: 8px;
  }
}

.customer-details-segment {
  margin-bottom: 16px;

  .customer-avatar {
    width: 55px;
    height: 55px;

    & > * {
      width: 100%;
      height: 100%;
    }
  }

  .customer-details {
    & > * {
      height: 28px;
    }
  }
}
