.preferred-by-grid__container {
  --ion-grid-padding: 0px;

  .preferred-by-grid__column {
    --ion-grid-column-padding: 0px;

    .customer-avatar {
      width: 52px;
      height: 52px;
    }
  }

  .avatar-column {
    margin-right: 16px;
    display: flex;
    align-items: center;
  }
}

.lead-direction__title {
  color: #47525D;
  font-size: 18px;
}

.lead-direction__title-subtitle {
  color: #47525D;
  margin-left: 32px;
  font-size: 15px;
}

.marker-icon {
  margin: 3px 14px 0 1px;
  width: 16px;
  height: 22px;
}

.oval-icon {
  margin: 5px 14px 0 1px;
  width: 17px;
  height: 17px;
}

.lead-timeline {
  position: absolute;
  top: 96px;
  height: 11%;
  left: 29px;
  border-left: 1px dashed #7E7E7E;
  z-index: 2;
}

.fev-lead-timeline { 
  position: absolute;
  top: 217px;
  height: 11%;
  left: 29px;
  border-left: 1px dashed #7E7E7E;
  z-index: 2;
}

.lead-direction-description {
  color: #828282;
  font-size: 13px;
  font-weight: 300;
  padding: 8px 0 16px 0;
}

.lead-address {
  display: block;
  padding: 16px 16px 8px 16px;
}

.available-show-chip {
  background-color: rgba(24,203,136,0.75);
  margin: 0 0 10px 0;
}

.disabled-show-chip {
  background-color: #FCABB5;
  margin: 0 0 10px 0;
}

.alert-disabled-date {
  .alert-message {
    font-size: 16px;
    color: #47525D;
    text-align: unset;
  }

  .alert-title {
    font-size: 20px;
    font-weight: 500;
    color: #F84B60;
  }

  .alert-head {
    text-align: unset;
    padding-bottom: 16px;
  }
}

.alert-topbar {
  text-align: center;
  padding: 2px 2px 5px 2px;
  color: #fff;
  background-color: rgb(255, 0, 0);
}
