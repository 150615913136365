@import 'src/theme/variables.scss';

.job-card-container {
  --ion-grid-padding: 0;

  ion-col {
    --ion-grid-column-padding: 0;
  }

  .row-flex-end {
    justify-content: flex-end;

    .service-icon, .call-icon, .message-icon {
      width: 50px;
      height: 50px;
    }

    .payout {
      margin: 8px 1px 0 0;
      font-size: $title2;
    }
  }
}

.job-number-row, .start-time-row {
  margin-bottom: 8px;
}

.timeline {
  position: absolute;
  top: 159px;
  height: 13%;
  left: 10px;
  border-left: 1px dashed #7E7E7E;
}

.available-chip {
  background-color: rgba(24,203,136,0.75);
  margin: 0 10px 10px 0;
}

.available-chip-label {
  color: #FFFFFF !important;
}

.disabled-chip {
  background-color: #FCABB5;
  margin: 0 10px 10px 0;
}

.disabled-chip-label {
  color: #D0021B !important;
}

.near-chip {
  background-color: #CFEFE3;
  margin: 0 10px 10px 0;
}

.near-chip-label {
  color: #16B77B !important;
}

.far-chip {
  background-color: #DAEBFB;
  margin: 0;
}

.far-chip-label {
  color: #2D95F4 !important;
}

.service-name-row,
.address-row,
.preferred-by-row,
.remark-row,
.footer-by-row {
  margin-bottom: 16px;
}

.job-number, .job-state-label {
  font-size: $caption;
  font-weight: $light;
}

.job-state {
  font-weight: $light;
}

.service-name {
  font-size: $title2;
}

.start-time, .address, .customer-text {
  font-size: $body;
  font-weight: $light;
}

.clock-icon-column {
  margin-right: 10px;
  margin-top: 4px;

  .clock-icon {
    width: 19px;
    height: 19px;
  }
}

.marker-icon-column {
  margin: 3px 0 0 1px;

  .marker-icon {
    width: 16px;
    height: 22px;
  }
}

.oval-icon-column {
  margin: 5px 0 0 1px;

  .oval-icon {
    width: 17px;
    height: 17px;
  }
}

.preferred-by-row {
  align-items: center;

  .customer-avatar-column {
    margin-right: 16px;

    .customer-avatar {
      width: 52px;
      height: 52px;
    }
  }
}

.message-col {
  margin-left: 10px;
}

.cash-chip {
  margin: 4px 0 0 0;
  padding: 0px 10px;
  border-width: 2px;
}

.text-red {
  color:rgb(255, 0, 0)
}