@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);

/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */
@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/assets/fonts/Kanit-Light.woff2") format("woff2");
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC; }

@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/assets/fonts/Kanit-Light-Latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/fonts/Kanit-Regular.woff2") format("woff2");
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC; }

@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/fonts/Kanit-Regular-Latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("/assets/fonts/Kanit-Medium.woff2") format("woff2");
  unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC; }

@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("/assets/fonts/Kanit-Medium-Latin.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/** Ionic CSS Variables **/
:root {
  --ion-font-family: Kanit, 'Helvetica Neue', 'Roboto', sans-serif;
  --ion-text-color: #47525d;
  /** primary **/
  --ion-color-primary: #1b4170;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #183963;
  --ion-color-primary-tint: #32547e;
  /** secondary **/
  --ion-color-secondary: #5b8ac3;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #5079ac;
  --ion-color-secondary-tint: #6b96c9;
  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;
  /** success **/
  --ion-color-success: #18cb88;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #15b378;
  --ion-color-success-tint: #2fd094;
  /** warning **/
  --ion-color-warning: #ff651f;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0591b;
  --ion-color-warning-tint: #ff7435;
  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;
  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;
  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #1b4170;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9; }

ion-button {
  --border-radius: 5px; }

@media only screen and (max-width: 376px) {
  ion-tab-bar ion-tab-button.md {
    font-size: 10px; } }

@media only screen and (max-width: 347px) {
  ion-tab-bar ion-tab-button.md {
    font-size: 9px; } }

@media only screen and (max-width: 320px) {
  ion-tab-bar ion-tab-button.md {
    font-size: 9px; }
    ion-tab-bar ion-tab-button.md ion-icon {
      font-size: 20px; } }

.assign-provider-success-toast {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.with-safe-area-top {
  padding-top: var(--ion-safe-area-top);
  background-color: var(--ion-color-primary); }

.with-safe-area-bottom {
  padding-bottom: env(--ion-safe-area-bottom); }

.no-background {
  background: none; }

.page-header {
  --min-height: 56px; }
  .page-header .page-header__back-button {
    padding-left: 8px; }
    .page-header .page-header__back-button.withBackButton.onBack ion-back-button {
      display: block; }
  .page-header .page-title {
    font-size: 20px;
    font-weight: 400;
    -webkit-padding-start: 0px;
            padding-inline-start: 0px;
    -webkit-padding-end: 0px;
            padding-inline-end: 0px; }

.page-header-no-border {
  --min-height: 56px;
  --border-width: 0 !important; }
  .page-header-no-border .page-header__back-button {
    padding-left: 8px; }
    .page-header-no-border .page-header__back-button.withBackButton.onBack ion-back-button {
      display: block; }
  .page-header-no-border .page-title {
    font-size: 20px;
    font-weight: 400; }

.page-header__seconday {
  --padding-bottom: 0px;
  --padding-end: 0px;
  --padding-start: 0px;
  --padding-top: 0px; }

.spinner-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.spinner-container--overlaid {
  position: absolute;
  z-index: 9999; }

.page-content-container {
  --background: none;
  background-image: url("/assets/img/background_service_icons.png");
  background-color: #f7f7f7;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover; }
  .page-content-container .page-refresher {
    z-index: 0; }

.conversation-list .conversation-list__item {
  --padding-top: 1rem;
  --padding-bottom: 1rem;
  --padding-start: 1rem;
  --padding-end: 1rem;
  --inner-padding-end: 0; }
  .conversation-list .conversation-list__item .conversation-list__img {
    width: 44px;
    height: 44px;
    border-radius: 50%; }
  .conversation-list .conversation-list__item .conversation-list__label {
    margin: 0 0 0 1rem; }
    .conversation-list .conversation-list__item .conversation-list__label p {
      margin: 0;
      font-size: 15px;
      color: var(--ion-text-color); }
    .conversation-list .conversation-list__item .conversation-list__label span {
      font-size: 13px; }

.input-container {
  border-radius: 5px;
  --padding-start: 16px;
  z-index: 0; }

.input-container--basic {
  border: 1px solid var(--ion-color-medium); }

.input-container--error {
  border: 1px solid var(--ion-color-danger); }

ion-item.item-interactive-disabled {
  --ion-item-background: #f4f4f4; }

.field-label {
  display: block;
  margin-bottom: 6px; }

.error-message-placeholder p {
  line-height: 16px;
  opacity: 0;
  margin: 0; }

ion-icon.verified {
  color: #00d810; }

.base-field.label.row {
  display: flex;
  justify-content: space-between; }
  .base-field.label.row span.edit-phone {
    color: #2D95F4;
    text-decoration: underline; }

.chat-form {
  display: flex; }
  .chat-form .file-upload {
    color: #B8B7B7; }
    .chat-form .file-upload input[type=file] {
      display: none; }
  .chat-form .input-conatiner {
    width: calc(100vw - 64px);
    margin: 6px 0.5rem; }
    .chat-form .input-conatiner .error-message-placeholder {
      display: none; }
    .chat-form .input-conatiner textarea {
      display: block;
      padding: 0.5rem 0; }
  .chat-form label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    float: right; }
    .chat-form label.active {
      color: var(--ion-color-primary); }
    .chat-form label.inactive {
      color: var(--ion-color-medium); }

.remark-text p {
  margin: 0; }
  .remark-text p.remark-text-success {
    color: var(--ion-color-success); }

ul {
  padding: 0;
  margin: 0; }
  ul .chip-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 8px; }
  ul li {
    position: relative;
    list-style: none;
    display: flex;
    transition: 0.5s all; }
    ul li.container-provider {
      justify-content: flex-end; }
    ul li:after {
      display: table;
      content: '';
      clear: both; }
    ul li .conversation-timstamp {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-end;
      margin: 0 0 1rem 0; }
      ul li .conversation-timstamp p {
        font-size: 12px;
        font-weight: 300;
        margin: 0;
        color: #B6B6B6; }
    ul li .conversation-content {
      margin: 0 0 1rem 0;
      max-width: 85%;
      display: inline-block;
      padding: 0.7rem 1rem;
      line-height: 1rem;
      min-height: 2rem;
      font-size: 14px;
      border-radius: 5px;
      word-break: break-all; }
      ul li .conversation-content p {
        font-size: 1rem;
        margin: 0; }
      ul li .conversation-content img {
        max-height: 200px; }
      ul li .conversation-content.customer {
        float: left;
        margin-right: 8px;
        border: 1px solid #E5E5E5;
        background-color: #E5E5E5;
        color: #000000; }
      ul li .conversation-content.provider {
        float: right;
        margin-left: 8px;
        border: 1px solid var(--ion-color-primary);
        background-color: var(--ion-color-primary);
        color: #FFFFFF; }

.upcoming-job-container {
  width: 100vw;
  box-shadow: 1px 2px 4px 0 rgba(182, 182, 182, 0.5);
  background-color: #FFF; }
  .upcoming-job-container .upcoming-job-detail {
    --padding-bottom: 1rem;
    --padding-end: 1rem;
    --padding-start: 1rem;
    --padding-top: 1rem; }
    .upcoming-job-container .upcoming-job-detail ion-label {
      font-weight: 300; }
    .upcoming-job-container .upcoming-job-detail .upcoming-job-detail__show-button {
      display: inline-flex;
      float: right; }
      .upcoming-job-container .upcoming-job-detail .upcoming-job-detail__show-button ion-text {
        padding-right: 4px; }
      .upcoming-job-container .upcoming-job-detail .upcoming-job-detail__show-button ion-icon {
        height: 14px;
        padding: 6px 0 3px 0; }
    .upcoming-job-container .upcoming-job-detail .upcoming-job-detail__description {
      --ion-grid-padding: 0; }
      .upcoming-job-container .upcoming-job-detail .upcoming-job-detail__description ion-col {
        --ion-grid-column-padding: 0; }
      .upcoming-job-container .upcoming-job-detail .upcoming-job-detail__description .upcoming-job-detail__service-name {
        font-size: 18px;
        padding-top: 18px; }
      .upcoming-job-container .upcoming-job-detail .upcoming-job-detail__description .upcoming-job-detail__label {
        width: 100%;
        font-weight: 300;
        padding-top: 8px;
        display: flex;
        flex-direction: row; }
        .upcoming-job-container .upcoming-job-detail .upcoming-job-detail__description .upcoming-job-detail__label .clock-icon {
          width: 12px;
          height: 24px;
          margin: 0;
          padding: 6px 0; }
        .upcoming-job-container .upcoming-job-detail .upcoming-job-detail__description .upcoming-job-detail__label .marker-icon {
          width: 12px;
          height: 24px;
          margin: 0;
          padding: 4px 0; }
        .upcoming-job-container .upcoming-job-detail .upcoming-job-detail__description .upcoming-job-detail__label .upcoming-job-detail__description-start-date, .upcoming-job-container .upcoming-job-detail .upcoming-job-detail__description .upcoming-job-detail__label .upcoming-job-detail__description-address {
          margin-left: 8px; }
        .upcoming-job-container .upcoming-job-detail .upcoming-job-detail__description .upcoming-job-detail__label .upcoming-job-detail__description-start-time {
          margin-left: 20px; }
      .upcoming-job-container .upcoming-job-detail .upcoming-job-detail__description .upcoming-job-detail__hide-button {
        padding-top: 8px;
        float: right; }
        .upcoming-job-container .upcoming-job-detail .upcoming-job-detail__description .upcoming-job-detail__hide-button ion-text {
          line-height: 24px;
          padding-right: 4px;
          vertical-align: middle; }
        .upcoming-job-container .upcoming-job-detail .upcoming-job-detail__description .upcoming-job-detail__hide-button ion-icon {
          -webkit-transform: rotate(180deg);
                  transform: rotate(180deg);
          vertical-align: middle; }

.page-content {
  background-color: #fff;
  --padding-start: 16px;
  --padding-end: 16px; }
  .page-content .page-content__pending-approve-img {
    display: block;
    margin: 16px auto; }
  .page-content .bank-select__bank-detail {
    --padding-start: 0; }
    .page-content .bank-select__bank-detail .bank-select__bank-logo-url {
      max-width: 50px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 9px; }
    .page-content .bank-select__bank-detail ion-label h3 {
      font-size: 17px;
      color: var(--ion-color-secondary);
      line-height: 25px;
      margin: 0; }
    .page-content .bank-select__bank-detail ion-label h4 {
      font-size: 15px;
      line-height: 25px; }
  .page-content h5 {
    text-align: center;
    margin: 0;
    padding-bottom: 16px; }
  .page-content p {
    margin: 0; }

.page-content {
  background-color: #fff;
  --padding-start: 16px;
  --padding-end: 16px; }
  .page-content .page-content__pending-approve-img {
    display: block;
    margin: 16px auto; }
  .page-content .bank-select__bank-detail {
    --padding-start: 0; }
    .page-content .bank-select__bank-detail .bank-select__bank-logo-url {
      max-width: 50px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 9px; }
    .page-content .bank-select__bank-detail ion-label h3 {
      font-size: 17px;
      color: var(--ion-color-secondary);
      line-height: 25px;
      margin: 0; }
    .page-content .bank-select__bank-detail ion-label h4 {
      font-size: 15px;
      line-height: 25px; }
  .page-content h5 {
    text-align: center;
    margin: 0;
    padding-bottom: 16px; }
  .page-content p {
    margin: 0; }

.forgot-password-page-core-layout {
  background-color: #f7f7f7; }
  .forgot-password-page-core-layout .grid-container {
    margin: 0 8px; }
    .forgot-password-page-core-layout .grid-container .text-content {
      padding: 16px; }
      .forgot-password-page-core-layout .grid-container .text-content p {
        font-size: 17px;
        line-height: 25px;
        margin: 0; }

.typo-small-paragraph {
  color: #222222bf;
  font-size: 13px;
  font-weight: 100; }

.typo-paragraph {
  color: #222222;
  font-size: 16px;
  font-weight: lighter; }

.error-paragraph {
  color: #ed3138; }

.white {
  color: #fff; }

.form-container .otp-phone-input {
  margin-right: 2px;
  flex-grow: 1;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 0px 5px 5px 0px !important;
  font-size: 18px !important;
  height: 100% !important;
  font-family: kanit;
  padding-left: 10px !important; }

.form-container .send-otp-small-paragraph-1 {
  margin-bottom: 12px; }

.form-container .send-otp-small-paragraph-2 {
  margin-top: 6px;
  margin-bottom: 12px; }

.form-container .input-container {
  display: flex;
  height: 53px; }

.form-container .country-box {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  height: 100%;
  margin-left: 2px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; }
  .form-container .country-box img {
    margin-left: 10px;
    margin-right: 10px;
    height: 25px; }
  .form-container .country-box .country-number {
    margin-right: 5px; }

.country-modal .country-modal-header-section .country-modal-input {
  margin-bottom: 12px;
  display: flex;
  justify-content: center; }
  .country-modal .country-modal-header-section .country-modal-input input {
    max-width: 80%;
    background-color: #acd1ff29;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    --padding-start: 12px;
    --padding-bottom: 12px;
    font-size: 14px; }

.country-modal .country-modal-content-section .country-modal-list {
  margin: 0 20px; }

.country-modal .country-modal-content-section .country-item {
  margin-bottom: 5px; }
  .country-modal .country-modal-content-section .country-item .country-avatar {
    margin-right: 6px; }
  .country-modal .country-modal-content-section .country-item .country-name {
    font-size: 16px;
    display: flex;
    align-items: center; }
  .country-modal .country-modal-content-section .country-item .country-code {
    font-size: 16px;
    display: flex;
    align-items: center; }

.big-btn {
  height: 61px; }



.home-page {
  --background: linear-gradient(
    296.18deg,
     #5B8AC3 0%,
     #2F588C 53.22%,
     #1B4170 100%
    );
  --padding-top: 6px;
  --padding-bottom: 6px;
  --padding-start: 6px;
  --padding-end: 6px; }
  .home-page .grid-container {
    height: 100%;
    display: flex;
    align-items: center;
    max-width: 400px; }
    .home-page .grid-container .workforce-logo {
      margin: 0 20px 24px 20px; }
    .home-page .grid-container .button-divider {
      display: flex;
      padding: 0 8px;
      margin: 16px 0; }
      .home-page .grid-container .button-divider .text {
        margin: 0 10px; }
      .home-page .grid-container .button-divider .line {
        flex: 30% 1;
        border-bottom: 1px solid #f4f5f8;
        -webkit-transform: translateY(-45%);
                transform: translateY(-45%); }

.job-completion-page--content-wrapper {
  padding: 16px; }
  .job-completion-page--content-wrapper .job-completion-page--content {
    border: 1px solid var(--ion-color-medium);
    border-radius: 12px;
    margin-top: 16px;
    background-color: white; }
    .job-completion-page--content-wrapper .job-completion-page--content .tap-sign-text {
      display: block;
      text-align: center;
      margin-top: 24px; }
    .job-completion-page--content-wrapper .job-completion-page--content .tap-sign-icon {
      height: 85px;
      display: block;
      margin: 8px auto 24px auto; }

.signature-pad-modal--close-button {
  --padding-start: 0px !important;
  margin-left: -4px !important; }

.signature-canvas-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--ion-color-light); }
  .signature-canvas-wrapper .signature-canvas-element {
    border-radius: 12px; }

.signature-pad-modal--action {
  width: 100% !important;
  margin: 8px; }

.customer-not-present {
  margin-bottom: 8px; }

.signature-pad-modal--root {
  padding-top: env(--ion-safe-area-top);
  background-color: var(--ion-color-primary);
  margin-bottom: env(--ion-safe-area-bottom); }

.payout-value-label-row {
  margin-bottom: 6px; }

.payout-display-value {
  font-size: 20px;
  margin: 4px 8px 0 0; }

.job-action-button-column {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.message-icon {
  width: 55px;
  height: 55px; }

.call-icon {
  width: 55px;
  height: 55px; }

.job-question-answer-container {
  margin-top: 14px; }
  .job-question-answer-container .job-text-question {
    display: block; }
  .job-question-answer-container .job-text-answer {
    font-weight: 300;
    white-space: break-spaces; }
  .job-question-answer-container .job-image-answer {
    width: calc((100% - 24px) / 3);
    height: 100px;
    display: inline-block;
    margin-top: 8px;
    border-radius: 6px;
    background-color: var(--ion-color-light); }
    .job-question-answer-container .job-image-answer > * {
      border-radius: 5px; }
  .job-question-answer-container .job-images-answer-container > *:nth-child(3n+2) {
    margin-left: 12px;
    margin-right: 12px; }

.segment-container {
  background: white;
  box-shadow: 0px 2px 4px 0px rgba(54, 54, 54, 0.3);
  margin-bottom: 16px; }
  .segment-container .segment-title {
    padding: 16px;
    border-bottom: 1px solid #CECECE; }
    .segment-container .segment-title .title {
      font-size: 20px;
      margin-right: 8px; }
  .segment-container .segment-content {
    padding: 16px; }

.my-custom-class-for-label {
  width: 50px;
  height: 20px;
  border: 1px solid #eb3a44;
  border-radius: 5px;
  background: #fee1d7;
  color: #eb3a44;
  text-align: center;
  line-height: 20px;
  font-weight: bold;
  font-size: 14px; }

[title="spot"] {
  opacity: 1 !important; }
  [title="spot"] img {
    border-radius: 50%; }

.google-map-container {
  position: relative;
  width: 100%;
  height: 100%; }
  .google-map-container .google-map {
    width: 100%;
    height: 100%; }
    .google-map-container .google-map .gm-style .gm-style-iw-d {
      overflow: auto !important; }
    .google-map-container .google-map .gm-ui-hover-effect {
      display: none !important; }
    .google-map-container .google-map.originRoute .gm-style-iw-c {
      padding: 8px 16px !important;
      color: #FFFFFF !important;
      background-color: #1E74D7 !important;
      box-shadow: 0 1px 2px 0 #B6B6B6, 0 2px 4px 0 #B6B6B6; }
    .google-map-container .google-map.originRoute .gm-style-iw-t::after {
      display: block;
      background-color: #1E74D7 !important;
      background: linear-gradient(0deg, #1E74D7 0%, #1E74D7 100%);
      box-shadow: -2px 2px 2px 0 rgba(178, 178, 178, 0.4);
      content: "";
      height: 15px;
      left: 0;
      position: absolute;
      top: 0;
      -webkit-transform: translate(-50%, -50%) rotate(-45deg);
              transform: translate(-50%, -50%) rotate(-45deg);
      width: 15px; }
    .google-map-container .google-map.destinationRoute .gm-style-iw-c {
      padding: 8px 16px !important;
      color: #FFFFFF !important;
      background-color: #fe2e2f !important;
      box-shadow: 0 1px 2px 0 #B6B6B6, 0 2px 4px 0 #B6B6B6; }
    .google-map-container .google-map.destinationRoute .gm-style-iw-t::after {
      display: block;
      background-color: #fe2e2f !important;
      background: linear-gradient(0deg, #fe2e2f 0%, #fe2e2f 100%);
      box-shadow: -2px 2px 2px 0 rgba(178, 178, 178, 0.4);
      content: "";
      height: 15px;
      left: 0;
      position: absolute;
      top: 0;
      -webkit-transform: translate(-50%, -50%) rotate(-45deg);
              transform: translate(-50%, -50%) rotate(-45deg);
      width: 15px; }
  .google-map-container .google-map-marker {
    position: absolute;
    height: 40px;
    top: -40px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    color: #f44336; }
  .google-map-container .google-map-places-container {
    position: absolute;
    padding: 10px;
    width: 100%; }
    .google-map-container .google-map-places-container.hidden {
      display: none; }
    .google-map-container .google-map-places-container .google-map-places-input {
      width: 100%;
      font-size: 1rem;
      padding: 10px;
      border-radius: 4px;
      border: 1px solid #ccc; }
      .google-map-container .google-map-places-container .google-map-places-input:focus, .google-map-container .google-map-places-container .google-map-places-input:hover {
        border: 1px solid #5b8ac3;
        outline: none; }

.job-delivery-container {
  background-color: #1B4170;
  padding-top: 0; }

.content-col-center {
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100px; }

.content-col-center-active {
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100px; }

.address-delivery-card {
  z-index: 1;
  position: absolute;
  top: 25px;
  padding: 16px; }
  .address-delivery-card .hydrated {
    padding: 0; }

.direction-image {
  width: 18px; }

.direction-button {
  margin: 0;
  margin-left: -24px;
  padding-bottom: 16px !important; }

.details-button {
  margin: 0;
  padding: 8px 0 !important; }

.next-icon {
  height: 30px; }

.address-delivery-card-header {
  padding: 0; }

.radio-input-list-container {
  margin-bottom: 0; }

.checkbox-input-list-container {
  margin-bottom: 0;
  position: relative;
  z-index: 0; }

.input-select-container {
  border: 1px solid var(--ion-color-medium);
  border-radius: 5px;
  padding-right: 12px; }

.camera-input-button__container {
  display: flex;
  flex-wrap: wrap; }
  .camera-input-button__container > * {
    width: calc((100% - 24px) / 3);
    margin: 10px 0;
    height: 110px; }
  .camera-input-button__container > *:nth-child(3n+2) {
    margin-left: 12px;
    margin-right: 12px; }
  .camera-input-button__container .camera-input-button {
    border: 2px dashed var(--ion-color-medium);
    border-radius: 12px; }
  .camera-input-button__container .photo-thumbnail__container {
    border-radius: 12px;
    position: relative;
    overflow-y: hidden; }
    .camera-input-button__container .photo-thumbnail__container > img {
      top: 50%;
      position: absolute;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }
    .camera-input-button__container .photo-thumbnail__container .photo-thumbnail__remove-button {
      --padding-bottom: 0;
      --padding-end: 0;
      --padding-start: 0;
      --padding-top: 0;
      position: absolute;
      top: 2px;
      right: 1px;
      opacity: 0.8; }

.photo-lightbox-modal {
  --background: rgba(0, 0, 0, 0.8); }
  .photo-lightbox-modal .photo-lightbox-modal__inner-wrapper {
    text-align: right; }

.modal-container {
  --height: 25%;
  align-items: flex-end; }

.modal-content {
  margin: 3%;
  padding: 5%; }

.text-android {
  font-size: 1em;
  font-weight: lighter; }

.text-ios {
  font-size: 1.2em;
  font-weight: lighter; }

.job-report-pane {
  background-color: white;
  padding: 16px;
  min-height: 100%; }
  .job-report-pane .not-for-customer-text {
    margin-bottom: 14px;
    display: block; }

.job-card-container {
  --ion-grid-padding: 0; }
  .job-card-container ion-col {
    --ion-grid-column-padding: 0; }
  .job-card-container .row-flex-end {
    justify-content: flex-end; }
    .job-card-container .row-flex-end .service-icon, .job-card-container .row-flex-end .call-icon, .job-card-container .row-flex-end .message-icon {
      width: 50px;
      height: 50px; }
    .job-card-container .row-flex-end .payout {
      margin: 8px 1px 0 0;
      font-size: 20px; }

.job-number-row, .start-time-row {
  margin-bottom: 8px; }

.timeline {
  position: absolute;
  top: 159px;
  height: 13%;
  left: 10px;
  border-left: 1px dashed #7E7E7E; }

.available-chip {
  background-color: rgba(24, 203, 136, 0.75);
  margin: 0 10px 10px 0; }

.available-chip-label {
  color: #FFFFFF !important; }

.disabled-chip {
  background-color: #FCABB5;
  margin: 0 10px 10px 0; }

.disabled-chip-label {
  color: #D0021B !important; }

.near-chip {
  background-color: #CFEFE3;
  margin: 0 10px 10px 0; }

.near-chip-label {
  color: #16B77B !important; }

.far-chip {
  background-color: #DAEBFB;
  margin: 0; }

.far-chip-label {
  color: #2D95F4 !important; }

.service-name-row,
.address-row,
.preferred-by-row,
.remark-row,
.footer-by-row {
  margin-bottom: 16px; }

.job-number, .job-state-label {
  font-size: 13px;
  font-weight: 300; }

.job-state {
  font-weight: 300; }

.service-name {
  font-size: 20px; }

.start-time, .address, .customer-text {
  font-size: 17px;
  font-weight: 300; }

.clock-icon-column {
  margin-right: 10px;
  margin-top: 4px; }
  .clock-icon-column .clock-icon {
    width: 19px;
    height: 19px; }

.marker-icon-column {
  margin: 3px 0 0 1px; }
  .marker-icon-column .marker-icon {
    width: 16px;
    height: 22px; }

.oval-icon-column {
  margin: 5px 0 0 1px; }
  .oval-icon-column .oval-icon {
    width: 17px;
    height: 17px; }

.preferred-by-row {
  align-items: center; }
  .preferred-by-row .customer-avatar-column {
    margin-right: 16px; }
    .preferred-by-row .customer-avatar-column .customer-avatar {
      width: 52px;
      height: 52px; }

.message-col {
  margin-left: 10px; }

.cash-chip {
  margin: 4px 0 0 0;
  padding: 0px 10px;
  border-width: 2px; }

.text-red {
  color: red; }

.group-header {
  height: 39px; }
  .group-header .group-label {
    margin: 10px auto; }

.list-item {
  --padding-top: 16px;
  --inner-padding-end: 16px;
  --border-width: 0 0 0.55px 0 !important; }

.empty-list-message__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 40px;
  text-align: center; }

.search-input-container {
  color: #47525d;
  background-color: #f7f7f7; }
  .search-input-container .search-input-group {
    background: white;
    display: flex;
    align-items: center;
    padding: 16px 16px 8px 16px; }
    .search-input-container .search-input-group .search-input {
      flex: 1 1;
      position: relative;
      display: flex;
      align-items: center;
      background-color: #fff; }
      .search-input-container .search-input-group .search-input ion-input {
        --padding-start: 30px;
        border: 1px solid #fff;
        border-radius: 6px;
        overflow: hidden;
        box-shadow: 0 1px 3px 0 #b6b6b6; }
        .search-input-container .search-input-group .search-input ion-input.has-focus {
          border: 1px solid #2d95f4; }
      .search-input-container .search-input-group .search-input ion-icon {
        position: absolute;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        top: 50%;
        left: 10px;
        color: #979797; }
    .search-input-container .search-input-group .close-btn {
      padding: 0 10px;
      cursor: pointer; }
  .search-input-container ul {
    margin: 0 5px 5px;
    box-shadow: 0 1px 3px 0 #b6b6b6;
    border-radius: 6px;
    overflow: hidden;
    background-color: #fff; }
    .search-input-container ul .history-icon {
      margin-right: 12px; }
    .search-input-container ul > li:first-child {
      padding: 0 16px; }
    .search-input-container ul > li:not(:first-child) {
      display: flex;
      align-items: center;
      padding: 8px 16px; }
      .search-input-container ul > li:not(:first-child):hover {
        background-color: #ddd;
        cursor: pointer; }
      .search-input-container ul > li:not(:first-child) > ion-icon {
        margin: 0 10px 0 0; }
      .search-input-container ul > li:not(:first-child) > label {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
  .search-input-container .search-result {
    padding: 0 16px 16px; }

.no-padding-item {
  --padding-start: 0;
  --inner-padding-end: 0; }

.job-details__item {
  margin-bottom: 8px; }
  .job-details__item .job-details__item-title-subtitle {
    margin: 0; }
    .job-details__item .job-details__item-title-subtitle > * {
      display: block; }
    .job-details__item .job-details__item-title-subtitle > *:first-child {
      font-size: 13px;
      font-weight: 300;
      margin-bottom: 8px; }
    .job-details__item .job-details__item-title-subtitle > *:last-child {
      font-size: 20px; }
  .job-details__item .job-details-service-icon {
    width: 60px;
    height: 60px;
    margin: 0; }

.job-details__order {
  --min-height: 0px;
  margin-bottom: 8px; }
  .job-details__order > *:first-child {
    margin: 0 8px 0 0; }

.text-red {
  color: red; }

.start-time-list {
  margin-top: 0; }
  .start-time-list .start-time-item {
    --padding-bottom: 8px;
    --padding-top: 8px; }
    .start-time-list .start-time-item .start-time__date {
      width: 40px;
      text-align: center;
      font-weight: 500; }
      .start-time-list .start-time-item .start-time__date > *:first-child {
        font-size: 20px; }
    .start-time-list .start-time-item .start_time__full-date-time {
      font-weight: 300; }
      .start-time-list .start-time-item .start_time__full-date-time .time-available-chip {
        background-color: rgba(24, 203, 136, 0.75);
        margin: 0;
        position: absolute;
        right: 8px;
        top: 16px;
        font-size: 10px;
        padding: 0 5px;
        height: 24px; }
      .start-time-list .start-time-item .start_time__full-date-time .time-available-chip-label {
        color: #FFFFFF !important; }
      .start-time-list .start-time-item .start_time__full-date-time .time-disabled-chip {
        background-color: #FCABB5;
        margin: 0;
        position: absolute;
        right: 8px;
        top: 16px;
        font-size: 10px;
        padding: 0 5px;
        height: 24px; }
      .start-time-list .start-time-item .start_time__full-date-time .time-disabled-chip-label {
        color: #D0021B !important; }
      .start-time-list .start-time-item .start_time__full-date-time > *:first-child {
        margin-bottom: 4px; }

.job-history-report-pane {
  background-color: white;
  padding: 16px 16px 16px 0; }

.job-image-answer {
  width: calc((100% - 24px) / 3);
  height: 100px;
  display: inline-block;
  margin-top: 8px;
  border-radius: 6px;
  background-color: var(--ion-color-light); }
  .job-image-answer > * {
    border-radius: 5px; }

.job-images-answer-container > *:nth-child(3n+2) {
  margin-left: 12px;
  margin-right: 12px; }

.group-header {
  height: 39px; }
  .group-header .group-label {
    margin: 10px auto; }

.list-item {
  --padding-top: 16px;
  --inner-padding-end: 16px;
  --border-width: 0 0 0.55px 0 !important; }

.item-open-google-map-app {
  --detail-icon-font-size: 25px;
  --padding-bottom: 8px;
  --padding-top: 8px; }

.team-job-comment-modal {
  --backdrop-opacity: 1 !important; }
  .team-job-comment-modal ion-backdrop {
    background: white; }
  .team-job-comment-modal ion-content ion-list.job-comment-list .job-comment-item ion-avatar {
    margin-top: 15px;
    margin-bottom: auto; }
  .team-job-comment-modal ion-footer {
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: rgba(0, 0, 0, 0.2);
    border-top-color: var(--ion-toolbar-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.2))));
    box-sizing: border-box;
    padding-bottom: 10px; }
    .team-job-comment-modal ion-footer ion-grid ion-row {
      min-height: 80px; }
      .team-job-comment-modal ion-footer ion-grid ion-row ion-col.comment-column ion-textarea {
        height: 100%; }
        .team-job-comment-modal ion-footer ion-grid ion-row ion-col.comment-column ion-textarea div.textarea-wrapper {
          height: inherit; }
          .team-job-comment-modal ion-footer ion-grid ion-row ion-col.comment-column ion-textarea div.textarea-wrapper textarea {
            height: inherit; }
      .team-job-comment-modal ion-footer ion-grid ion-row ion-col.icon-column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 0 20px 0 auto; }
        .team-job-comment-modal ion-footer ion-grid ion-row ion-col.icon-column ion-icon {
          font-size: 18px;
          width: 25px;
          height: 25px;
          color: #47515d;
          margin-top: 5px; }
          .team-job-comment-modal ion-footer ion-grid ion-row ion-col.icon-column ion-icon.navigate-icon {
            font-size: 25px;
            color: #2168c0;
            width: 35px;
            height: 35px;
            margin-top: auto; }
    .team-job-comment-modal ion-footer.expanded {
      height: 100%; }
      .team-job-comment-modal ion-footer.expanded ion-grid {
        height: inherit; }
        .team-job-comment-modal ion-footer.expanded ion-grid ion-row {
          height: 100%; }
          .team-job-comment-modal ion-footer.expanded ion-grid ion-row ion-col.comment-column ion-textarea {
            height: 100%; }
            .team-job-comment-modal ion-footer.expanded ion-grid ion-row ion-col.comment-column ion-textarea div.textarea-wrapper {
              height: inherit; }
              .team-job-comment-modal ion-footer.expanded ion-grid ion-row ion-col.comment-column ion-textarea div.textarea-wrapper textarea {
                height: inherit; }
          .team-job-comment-modal ion-footer.expanded ion-grid ion-row ion-col.icon-column {
            justify-content: flex-start; }
            .team-job-comment-modal ion-footer.expanded ion-grid ion-row ion-col.icon-column ion-icon.navigate-icon {
              display: none; }

ion-row.comment .mock-textarea {
  border: 1px solid #cecece66;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
  height: 166px;
  color: #dee0e2; }

ion-row.comment ion-textarea {
  border: 1px solid #cecece66;
  border-radius: 5px;
  --padding-start: 10px;
  margin-bottom: 10px; }

ion-row.comment .comment-btn-box {
  margin-top: 8px; }
  ion-row.comment .comment-btn-box .expand-comment-text {
    text-decoration: underline;
    color: #2e95f4; }

.item-no-padding {
  --padding-start: 0;
  --inner-padding-end: 0; }
  .item-no-padding > * {
    margin: 0; }
    .item-no-padding > * > * {
      margin: 0;
      height: 44px;
      font-size: 17px; }
  .item-no-padding > *:first-child {
    margin-right: 8px; }
  .item-no-padding > *:last-child {
    margin-left: 8px; }

.customer-details-segment {
  margin-bottom: 16px; }
  .customer-details-segment .customer-avatar {
    width: 55px;
    height: 55px; }
    .customer-details-segment .customer-avatar > * {
      width: 100%;
      height: 100%; }
  .customer-details-segment .customer-details > * {
    height: 28px; }

.job-report-pane {
  background-color: white;
  padding: 16px;
  min-height: 100%; }
  .job-report-pane .not-for-customer-text {
    margin-bottom: 14px;
    display: block; }


.group-header {
  height: 39px; }
  .group-header .group-label {
    margin: 10px auto; }

.list-item {
  --padding-top: 16px;
  --inner-padding-end: 16px;
  --border-width: 0 0 0.55px 0 !important; }

.job-card-container {
  --ion-grid-padding: 0; }
  .job-card-container ion-col {
    --ion-grid-column-padding: 0; }
  .job-card-container .row-flex-end {
    justify-content: flex-end; }
    .job-card-container .row-flex-end .service-icon, .job-card-container .row-flex-end .call-icon, .job-card-container .row-flex-end .message-icon {
      width: 50px;
      height: 50px; }
    .job-card-container .row-flex-end .payout {
      margin: 8px 1px 0 0;
      font-size: 20px; }

.job-number-row, .start-time-row {
  margin-bottom: 8px; }

.timeline {
  position: absolute;
  top: 159px;
  height: 13%;
  left: 10px;
  border-left: 1px dashed #7E7E7E; }

.service-name-row,
.address-row,
.preferred-by-row,
.footer-by-row {
  margin-bottom: 16px; }

.job-number, .job-state-label {
  font-size: 13px;
  font-weight: 300; }

.job-state {
  font-weight: 300; }

.service-name {
  font-size: 20px; }

.start-time, .address, .customer-text {
  font-size: 17px;
  font-weight: 300; }

.clock-icon-column {
  margin-right: 10px;
  margin-top: 4px; }
  .clock-icon-column .clock-icon {
    width: 19px;
    height: 19px; }

.marker-icon-column {
  margin: 3px 0 0 1px; }
  .marker-icon-column .marker-icon {
    width: 16px;
    height: 22px; }

.oval-icon-column {
  margin: 5px 0 0 1px; }
  .oval-icon-column .oval-icon {
    width: 17px;
    height: 17px; }

.preferred-by-row {
  align-items: center; }
  .preferred-by-row .customer-avatar-column {
    margin-right: 16px; }
    .preferred-by-row .customer-avatar-column .customer-avatar {
      width: 52px;
      height: 52px; }

.message-col {
  margin-left: 10px; }

.cash-chip {
  margin: 4px 0 0 0;
  padding: 0px 10px;
  border-width: 2px; }

.date-range-picker-label {
  padding-bottom: 8px; }

.DateRangePickerInput input {
  text-align: center; }

.DateRangePicker_picker {
  z-index: 5; }

.DateRangePickerInput input {
  font-size: 16px; }

.DateRangePickerInput_arrow {
  line-height: 5px; }

.filter-lead-form-container {
  padding: 16px;
  background-color: white;
  height: 100%; }

.date-range-field {
  padding-bottom: 24px; }

.announcement-container {
  margin-top: 38px;
  margin-bottom: 52px;
  height: 100%;
  width: 100%;
  object-fit: cover;
  align-items: center;
  display: flex !important;
  height: 143px; }
  .announcement-container .announcement-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    align-items: center;
    display: flex !important;
    height: 143px;
    box-shadow: 1px 2px 4px 0 #B6B6B6;
    border-radius: 6px;
    overflow: auto; }

.swiper-pagination-bullet-active {
  background: #2D95F4 !important; }

.swiper-pagination-bullet {
  background: #545454; }

.swiper-slide-active .announcement-img {
  margin-top: 16px;
  margin-bottom: 26px;
  height: 175px; }

.slide-container {
  background-color: white; }

.address-fab {
  width: 100%;
  padding: 0.5rem 1rem; }
  .address-fab .address-item {
    --color: var(--ion-color-primary);
    --background: #F4F4F4;
    --detail-icon-color: var(--ion-color-primary);
    --detail-icon-opacity: 100%;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 #8E8E93;
    overflow: initial; }
    .address-fab .address-item .address-item-name-container {
      margin-right: auto;
      padding: 8px 0; }
    .address-fab .address-item .address-label-container {
      padding: 0; }
    .address-fab .address-item .address-update-button {
      --background: none;
      --background-activated: none;
      --background-focused: none;
      --background-hover: none;
      height: 100%; }
    .address-fab .address-item .address-update-icon {
      color: black;
      font-size: 40px; }
    .address-fab .address-item .label-text {
      color: #2D95F4;
      font-size: 16px;
      line-height: 37px; }
    .address-fab .address-item .label-icon {
      height: 25px;
      padding-right: 6px; }
    .address-fab .address-item .update-label-container {
      position: absolute;
      top: -20px;
      right: 3px;
      z-index: 10;
      padding: 2px 12px;
      background-color: #1B4170;
      color: #ffffff;
      font-size: 16px;
      line-height: 22px; }
    .address-fab .address-item .arrow-down {
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #1B4170;
      position: absolute;
      top: 6px;
      right: 32px; }

.filter-btn-container {
  font-size: 14px !important;
  max-height: 33px !important;
  max-width: 100% !important;
  padding: 2px 0 !important;
  --color: #47525D;
  --color-activated: #47525D;
  --color-focused: #47525D;
  --color-hover: #47525D;
  --padding-start: 6px;
  --padding-end: 6px;
  --border-color: #47525D;
  --background: none;
  --background-activated: none;
  --background-focused: none;
  --background-hover: none; }
  .filter-btn-container.main {
    float: left; }

.check-icon-column {
  margin: 2px 10px 0 1px; }
  .check-icon-column .check-icon {
    width: 24px;
    height: 24px; }

.filter-distance-lead-container {
  --ion-grid-padding: 0; }
  .filter-distance-lead-container ion-col {
    --ion-grid-column-padding: 0; }
  .filter-distance-lead-container .row-flex-end {
    justify-content: flex-end; }

.filter-lead-row-first,
.filter-lead-row {
  padding: 24px; }

.filter-lead-row-first {
  border-bottom: 1px solid #DDDDDD; }

.filter-btn-icon {
  font-size: 18px !important;
  padding-right: 5px; }

.modal-distance-filter-container {
  --height: 153px;
  align-items: flex-end; }

img.filter-btn-icon {
  height: 13px; }

.filter-lead-container {
  background: white;
  padding: 0 16px 10px 16px; }
  .filter-lead-container ion-col.ios.hydrated {
    padding: 0 !important;
    text-align: right !important; }
  .filter-lead-container .search-result {
    text-align: left !important; }

.filter-label-container {
  display: flex;
  background-color: #D8D8D8;
  border-radius: 4px;
  padding: 4px 8px;
  color: #47525D;
  font-size: 14px;
  align-items: center; }
  .filter-label-container .arrow-btn-icon {
    padding: 0 5px; }
  .filter-label-container .cancel-icon-container {
    margin-left: auto;
    align-items: center;
    display: flex;
    font-size: 17px; }
  .filter-label-container .filter-label {
    display: flex;
    align-items: center; }

.filter-label-container-status {
  margin-top: 8px;
  display: flex;
  background-color: #D8D8D8;
  border-radius: 4px;
  padding: 4px 8px;
  color: #47525D;
  font-size: 14px;
  align-items: center; }
  .filter-label-container-status .arrow-btn-icon {
    padding: 0 5px; }
  .filter-label-container-status .cancel-icon-container {
    margin-left: auto;
    align-items: center;
    display: flex;
    font-size: 17px; }
  .filter-label-container-status .filter-label {
    display: flex;
    align-items: center; }

.row-filter-container {
  align-items: center !important; }

.static-map-container {
  position: relative;
  box-shadow: 0 1px 3px 0 #b6b6b6; }
  .static-map-container .static-map-expand-icon {
    position: absolute;
    bottom: 10px;
    right: 10px; }

.preferred-by-grid__container {
  --ion-grid-padding: 0px; }
  .preferred-by-grid__container .preferred-by-grid__column {
    --ion-grid-column-padding: 0px; }
    .preferred-by-grid__container .preferred-by-grid__column .customer-avatar {
      width: 52px;
      height: 52px; }
  .preferred-by-grid__container .avatar-column {
    margin-right: 16px;
    display: flex;
    align-items: center; }

.lead-direction__title {
  color: #47525D;
  font-size: 18px; }

.lead-direction__title-subtitle {
  color: #47525D;
  margin-left: 32px;
  font-size: 15px; }

.marker-icon {
  margin: 3px 14px 0 1px;
  width: 16px;
  height: 22px; }

.oval-icon {
  margin: 5px 14px 0 1px;
  width: 17px;
  height: 17px; }

.lead-timeline {
  position: absolute;
  top: 96px;
  height: 11%;
  left: 29px;
  border-left: 1px dashed #7E7E7E;
  z-index: 2; }

.fev-lead-timeline {
  position: absolute;
  top: 217px;
  height: 11%;
  left: 29px;
  border-left: 1px dashed #7E7E7E;
  z-index: 2; }

.lead-direction-description {
  color: #828282;
  font-size: 13px;
  font-weight: 300;
  padding: 8px 0 16px 0; }

.lead-address {
  display: block;
  padding: 16px 16px 8px 16px; }

.available-show-chip {
  background-color: rgba(24, 203, 136, 0.75);
  margin: 0 0 10px 0; }

.disabled-show-chip {
  background-color: #FCABB5;
  margin: 0 0 10px 0; }

.alert-disabled-date .alert-message {
  font-size: 16px;
  color: #47525D;
  text-align: unset; }

.alert-disabled-date .alert-title {
  font-size: 20px;
  font-weight: 500;
  color: #F84B60; }

.alert-disabled-date .alert-head {
  text-align: unset;
  padding-bottom: 16px; }

.alert-topbar {
  text-align: center;
  padding: 2px 2px 5px 2px;
  color: #fff;
  background-color: red; }

.main-job-card-container {
  padding-bottom: 8px;
  --ion-grid-padding: 0; }
  .main-job-card-container ion-col {
    --ion-grid-column-padding: 0; }
  .main-job-card-container .main-job-card-service-name {
    color: #47525D;
    font-weight: 500;
    margin-bottom: -3px; }
  .main-job-card-container .main-job-card-start-time {
    color: #7E7E7E; }
  .main-job-card-container .row-flex-end {
    justify-content: flex-end;
    font-family: "roboto";
    margin-top: 1px; }
    .main-job-card-container .row-flex-end .payout {
      margin: 8px 1px 0 0; }
      .main-job-card-container .row-flex-end .payout .payout-symbol {
        font-size: 14px;
        font-weight: 600;
        font-family: 'thonburi roboto'; }
      .main-job-card-container .row-flex-end .payout .payout-price {
        font-size: 24px;
        font-weight: 600; }

.main-job-card-service-icon {
  width: 48px;
  height: 48px;
  margin-top: 2px; }

.group-header {
  height: 39px; }
  .group-header .group-label {
    margin: 10px auto; }

.main-list-item {
  margin-left: -10px;
  --padding-top: 16px;
  --inner-padding-end: 16px;
  border: none; }

.main-page {
  background: white;
  height: 100%; }

.main-page-safe-area-top {
  background: white; }

.main-page-content {
  padding-top: env(--ion-safe-area-top); }

.main-page-big-square {
  margin-top: 3px; }

.main-page-container {
  margin-left: 8px;
  margin-right: 8px; }

.main-page-list-header-text-row {
  margin-bottom: -7px; }

.main-page-list-info-col {
  margin-bottom: 0px; }
  .main-page-list-info-col .main-page-list-info-text {
    font-size: 16px;
    color: #18CB88; }

.main-page-side-margin-left {
  margin-left: 6px; }

.main-page-side-margin-right {
  margin-right: 6px; }

.main-page-list-link-text {
  font-size: 16px; }

.main-page-provider-header {
  margin-left: 4px;
  margin-right: 4px; }

.main-page-list-info-link {
  display: flex;
  justify-content: flex-end;
  margin-right: 4px; }

.main-page-list-link-text {
  cursor: pointer; }

.main-page-list-header-text {
  font-size: 24px;
  font-weight: 500;
  color: #47525D; }

.main-page-small-square-row {
  padding-top: env(--ion-safe-area-top); }

.info-box-container {
  min-height: 154px;
  max-height: 600px;
  min-width: 154px;
  margin-right: 3px;
  border-radius: 5px; }

.info-box-big-container {
  min-height: 139px;
  min-width: 154px;
  max-height: 600px;
  border-radius: 5px;
  margin-right: 3px; }

.info-box-content {
  height: 100%;
  width: 100%;
  font-size: 16px;
  color: #fff; }
  .info-box-content .info-box-top-heading {
    margin-left: 16px;
    margin-right: 16px;
    max-width: 17ch;
    margin-top: 16px; }
  .info-box-content .info-box-top-heading-big {
    margin-left: 16px;
    margin-top: 30px; }
  .info-box-content .info-box-top-data {
    margin-left: 16px; }
  .info-box-content .info-box-bottom-content {
    margin-top: 16px; }
  .info-box-content .info-box-bottom-content-big {
    margin-top: 14px; }
  .info-box-content .info-box-bottom-heading {
    margin-left: 16px;
    font-size: 13px; }
  .info-box-content .info-box-bottom-data {
    margin-left: 16px;
    font-size: 26px;
    font-family: "roboto";
    font-weight: 500; }
    .info-box-content .info-box-bottom-data .info-box-bottom-data-first-char {
      font-size: 16px;
      font-weight: 700;
      font-family: "thonburi roboto"; }
    .info-box-content .info-box-bottom-data .info-box-bottom-data-first-char-big {
      font-size: 20px;
      font-weight: 700;
      font-family: "thonburi roboto"; }
    .info-box-content .info-box-bottom-data .info-box-big-text {
      font-size: 26px; }

.provider-header-container {
  margin-left: 6px;
  margin-right: 6px; }

.provider-header-display-name {
  color: #47525D;
  font-size: 20px;
  font-weight: 500;
  width: 100%; }
  .provider-header-display-name IonText {
    margin: 0;
    padding: 0; }

.provider-header-rating {
  height: 16px;
  width: 16px;
  margin-top: 5px;
  margin-left: 7px;
  margin-right: 7px; }

.provider-header-img-col {
  display: flex;
  margin-top: 6px;
  justify-content: flex-end;
  align-items: center; }
  .provider-header-img-col IonAvatar {
    width: 55px;
    height: 55px; }

.policy-terms-modal {
  z-index: 20010 !important; }
  .policy-terms-modal .policy-modal-header .title {
    padding: 30px 0; }
  .policy-terms-modal .policy-modal-header ion-toolbar.first-bar {
    --border-width: 0 0 0.55px;
    --min-height: 70px; }
    .policy-terms-modal .policy-modal-header ion-toolbar.first-bar ion-title {
      padding-inline: 50px; }
    .policy-terms-modal .policy-modal-header ion-toolbar.first-bar .back-button {
      padding-left: 20px; }
  .policy-terms-modal .policy-modal-header ion-toolbar.second-bar > div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0; }
    @media only screen and (max-width: 376px) {
      .policy-terms-modal .policy-modal-header ion-toolbar.second-bar > div {
        padding: 10px 15px;
        font-size: small; } }
  .policy-terms-modal .policy-modal-header ion-toolbar.second-bar .language-select {
    border: 1px solid #c2c6ca;
    border-radius: 25px;
    padding: 5px 10px; }
  .policy-terms-modal .policy-modal-content {
    padding: 10px 24px; }
    @media only screen and (max-width: 376px) {
      .policy-terms-modal .policy-modal-content {
        padding: 10px 15px; } }
  .policy-terms-modal .policy-modal-footer ion-toolbar .buttons {
    display: flex;
    justify-content: space-evenly;
    padding: 10px 24px; }
    .policy-terms-modal .policy-modal-footer ion-toolbar .buttons .contact-button {
      flex: 1 1; }

.not-found-page {
  --background: linear-gradient(
    296.18deg,
     #5B8AC3 0%,
     #2F588C 53.22%,
     #1B4170 100%
    ); }

.address-form-container {
  padding: 16px;
  background-color: white;
  height: 100%; }
  .address-form-container .update-current-location-btn {
    background-color: #1B4170;
    color: #FFFFFF;
    padding: 8px 14px;
    border-radius: 8px;
    position: absolute;
    top: 65%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 195px; }
  .address-form-container .update-home-address-icon {
    font-size: 30px;
    padding-right: 8px; }
  .address-form-container .update-current-location-map-container {
    position: relative;
    box-shadow: 0 1px 3px 0 #b6b6b6; }

.address-page .submit-btn {
  border: 2px solid #1B4170;
  --ion-color-base: #ffffff !important;
  border-radius: 8px; }

.address-page .address-footer-container {
  padding: 8px; }

.field-label {
  display: block;
  margin-bottom: 6px; }

.error-message-placeholder p {
  line-height: 16px;
  opacity: 0;
  margin: 0; }

.bank-select__label {
  --inner-padding-bottom: 0;
  --inner-padding-end: 0;
  --inner-padding-start: 0;
  --inner-padding-top: 0;
  --padding-bottom: 0;
  --padding-end: 0;
  --padding-start: 0;
  --padding-top: 0; }
  .bank-select__label > ion-avatar {
    margin-right: 9px; }
  .bank-select__label > ion-label > h3 {
    font-size: 15px; }

.bank-account-form-container {
  padding: 16px;
  background-color: white; }
  .bank-account-form-container input[type=tel]:focus {
    outline: none; }
  .bank-account-form-container .annotation {
    margin: 0; }

.page-content {
  background-color: #fff;
  --padding-start: 16px;
  --padding-end: 16px; }
  .page-content .page-content__pending-approve-img {
    display: block;
    margin: 16px auto; }
  .page-content .bank-select__bank-detail {
    --padding-start: 0; }
    .page-content .bank-select__bank-detail .bank-select__bank-logo-url {
      max-width: 50px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 9px; }
    .page-content .bank-select__bank-detail ion-label h3 {
      font-size: 17px;
      color: var(--ion-color-secondary);
      line-height: 25px;
      margin: 0; }
    .page-content .bank-select__bank-detail ion-label h4 {
      font-size: 15px;
      line-height: 25px; }
  .page-content h5 {
    text-align: center;
    margin: 0;
    padding-bottom: 16px; }
  .page-content p {
    margin: 0; }

.my-address-page {
  padding: 16px;
  height: 100%;
  background-color: white; }
  .my-address-page .marker-icon-image {
    width: 20px;
    position: absolute; }
  .my-address-page .person-image {
    width: 20px;
    height: 20px; }

.my-address-footer-container {
  padding: 8px; }


.my-available-distance-page .page-content-container {
  background-color: #ffffff;
  background-image: none; }

.my-available-distance-page .wrapper {
  padding: 16px; }

.my-available-distance-page .label {
  color: #47525D;
  font-size: 18px;
  padding-bottom: 16px; }

.bank-account-card-contanier .row-flex-end {
  justify-content: flex-end;
  padding-top: 11px; }

.bank-account-card-contanier .bank-account-text-container {
  padding-bottom: 16px; }

.bank-account-card-contanier .bank-account-top-container {
  padding-bottom: 16px;
  padding-top: 11px; }

.bank-account-card-contanier .bank-account-bottom-container {
  padding-bottom: 11px; }

.bank-account-card-contanier .bank-logo {
  width: 25px;
  height: 25px;
  margin-left: 8px;
  margin-right: 8px; }

.bank-account-card-contanier .bank-edit-button {
  --padding-bottom: 16px;
  --padding-end: 16px;
  --padding-start: 16px;
  --padding-top: 16px;
  --color: #47525D; }

.calendar-card {
  margin: 0;
  margin-bottom: 8px;
  background-color: #E9EFF5;
  box-shadow: none; }

.card-header-container {
  padding: 8px; }

.center-text-subtitle {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 400; }

.center-text-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  font-weight: 500; }

.center-text-content {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  padding: 8px 0; }

.close-icon {
  height: 30px;
  position: absolute;
  z-index: 1;
  right: -8px;
  width: 30px;
  top: -8px; }

.pop-up-calendar-card {
  position: absolute;
  z-index: 11;
  top: 42vh;
  padding: 16px;
  margin: 16px;
  font-size: 15px;
  background-color: #5B8AC3;
  color: #FFFFFF; }

.pop-up-center-text-content {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0;
  padding: 0;
  line-height: 23px; }

.pop-up-close-icon {
  position: absolute;
  z-index: 12;
  margin-right: 3px;
  right: 0;
  width: 40px;
  height: 40px;
  top: 42vh; }

.date-range-picker-label {
  padding-bottom: 8px; }

.DateRangePickerInput input {
  text-align: center; }

.DateRangePickerInput input {
  font-size: 16px; }

.DateRangePickerInput_arrow {
  line-height: 5px; }

.input-time-range input.native-input.sc-ion-input-ios {
  opacity: 1 !important; }

.carlendar-page {
  background-color: #ffffff;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover; }

.my-calendar {
  padding: 0;
  background-color: #ffffff; }

.remark-for-calendar {
  padding-left: 16px;
  color: #47525D;
  font-size: 15px;
  font-weight: 300; }
  .remark-for-calendar .icon-actived-date {
    margin-top: 3px;
    margin-right: 10px;
    color: #2D95F4; }
  .remark-for-calendar .icon-selected-date {
    color: #F05F60;
    margin-top: 3px;
    margin-right: 10px; }
  .remark-for-calendar .icon-already-date {
    color: #18CB88;
    margin-top: 3px;
    margin-right: 10px; }

.full-background-block {
  width: calc(100% + 2rem);
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: 10; }

.full-spot-light {
  background-color: black;
  width: calc(100% + 2rem);
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: 11;
  opacity: 0.9; }

.text-full-spot-light {
  top: 20vh;
  margin-left: auto;
  margin-right: auto;
  left: 10%;
  right: 10%;
  position: absolute;
  z-index: 11;
  color: #FF9F00; }
  .text-full-spot-light h3 {
    font-size: 26px; }

.text-full-spot-light-title {
  top: 29vh;
  margin-left: auto;
  margin-right: auto;
  left: 10%;
  right: 10%;
  position: absolute;
  z-index: 11;
  color: #FFFFFF;
  font-size: 20px; }

.selected-logo {
  width: 170px;
  top: 47vh;
  margin-left: auto;
  margin-right: auto;
  left: 10%;
  right: 10%;
  position: absolute;
  z-index: 11;
  color: #FFFFFF; }

.text-full-spot-light-footer {
  top: 68vh;
  margin-left: auto;
  margin-right: auto;
  right: 0;
  left: 0;
  text-align: center;
  position: absolute;
  z-index: 11;
  color: #FFFFFF; }

.bank-edit-button {
  margin: 0px 35%; }

.Calendar__day.-ltr.-today.disabled_provider_date {
  background-color: #F05F60;
  color: #ffffff !important;
  border: #F05F60 solid 1px;
  border-radius: 50%; }

.Calendar__day.-ltr.disabled_provider_date {
  background-color: #F05F60;
  color: #ffffff;
  border: #F05F60 solid 1px;
  border-radius: 50%; }

.Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after {
  content: '';
  position: absolute;
  bottom: 9px;
  display: block;
  width: 1em;
  height: 1.1px;
  background: #000;
  left: 50%;
  opacity: 0.5;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  transition: 0.2s; }

.Calendar__day.-ltr.disabled_provider_date.-selected {
  background-color: #F84B60 !important;
  color: #ffffff !important;
  border: #F84B60 solid 1px !important; }

.content-calendar-page-form {
  padding: 16px; }

.content-calendar-page p {
  font-size: 18px; }

.content-calendar-page p, .content-calendar-page h5 {
  padding: 0 16px;
  font-weight: 400; }

.job-time {
  padding: 0 0 16px 40px; }

.job-time-calendar {
  padding: 0 16px 0 16px; }

.header-selected-disabled-date {
  padding: 0 0 0 20px; }

.active-calendar .Calendar {
  max-width: 100vw;
  width: 100%;
  padding-top: 0;
  box-shadow: none; }

.active-calendar .Calendar__day.-ltr.-selected {
  background-color: #18CB88 !important;
  color: #ffffff !important;
  border: #18CB88 solid 1px !important; }

.active-calendar .Calendar__weekDays {
  padding: 0;
  color: rgba(0, 0, 0, 0.8);
  font-size: 1.45em; }

.active-calendar .Calendar__header {
  background-color: #E9EFF5;
  margin-bottom: 8px; }

.active-calendar .Calendar__monthText {
  color: #5B8AC3;
  font-size: 18px; }

.active-calendar .Calendar__yearText {
  color: #5B8AC3;
  font-size: 18px; }

.active-calendar .Calendar__monthArrow {
  color: #5B8AC3; }

.active-calendar .Calendar__section.-shown {
  padding: 0; }

.active-calendar .Calendar__day.-ltr {
  width: calc(100% / 10);
  margin: 16px 8px; }

.active-calendar .Calendar__sectionWrapper {
  height: 360px; }

.active-calendar .Calendar__day.-ltr.-weekend {
  z-index: 2; }

.active-calendar .Calendar__section.-hiddenNext {
  z-index: 1;
  margin-left: 26px; }

.active-calendar .Calendar__day.-ltr.active_provider_date.-disabled {
  color: #CECECE !important; }

.active-calendar .active_provider_date:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  color: #2D95F4; }

.active-calendar .active_provider_date.-disabled:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected):hover {
  color: #CECECE !important; }

.active-calendar .active_provider_date:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected):hover {
  color: #2D95F4 !important; }

.active-calendar .Calendar__day.-ltr.disabled_provider_date:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
  background-color: #F05F60;
  color: #ffffff;
  border: #F05F60 solid 1px; }

.active-calendar .Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
  background-color: #ffffff; }

.active-calendar .Calendar__day.active_provider_date:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected):hover {
  background-color: #ffffff; }

.active-calendar .group_available_active_disable_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background-image: url("/assets/img/dot-mix.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.active-calendar .group_available_active_disable_day.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url("/assets/img/dot-mix.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.active-calendar .pair_available_active_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background-image: url("/assets/img/dot-blue-green.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.active-calendar .pair_available_active_day.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url("/assets/img/dot-blue-green.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.active-calendar .pair_available_disable_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background-image: url("/assets/img/dot-red-green.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.active-calendar .pair_available_disable_day.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url("/assets/img/dot-red-green.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.active-calendar .pair_active_disable_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background-image: url("/assets/img/dot-blue-red.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.active-calendar .pair_active_disable_day.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url("/assets/img/dot-blue-red.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.active-calendar .active_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background-image: url("/assets/img/dot-blue.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.active-calendar .active_day.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url("/assets/img/dot-blue.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.active-calendar .available_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background-image: url("/assets/img/dot-green.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.active-calendar .available_day.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url("/assets/img/dot-green.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.active-calendar .disable_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background-image: url("/assets/img/red-dot.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.active-calendar .disable_day.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url("/assets/img/red-dot.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.active-calendar .Calendar__day.-ltr.disabled_provider_date.-disabled {
  background-color: #F05F60 !important;
  border: #F05F60 solid 1px;
  border-radius: 50%;
  color: #CECECE !important; }

.calendar-main-page {
  background: #ffffff; }

.alert-calendar .alert-message {
  font-size: 15px;
  text-align: unset; }

.alert-calendar .alert-title {
  font-size: 18px;
  font-weight: 400; }

.alert-calendar .alert-head {
  text-align: unset;
  padding-bottom: 16px; }

.calendar-card-row {
  padding: 0 6px; }

.modal-toolbar {
  --min-height: 56px; }
  .modal-toolbar .page-title {
    font-size: 20px;
    font-weight: 400;
    -webkit-padding-start: 0px;
            padding-inline-start: 0px;
    -webkit-padding-end: 0px;
            padding-inline-end: 0px; }

.child {
  padding-bottom: 16px; }

.text-toast-button {
  position: absolute;
  right: 68px;
  width: 96px;
  color: #FFFFFF; }

.text-toast-button-secondary {
  position: absolute;
  right: 53px;
  width: 96px;
  color: #ffffff; }

.calendar-devide {
  box-sizing: border-box;
  height: 1px;
  width: 100%;
  margin-bottom: 16px;
  border: 1px dashed #CECECE; }

.carlendar-page {
  background-color: #ffffff;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover; }

.my-calendar {
  padding: 0;
  background-color: #ffffff; }

.remark-for-calendar {
  padding-left: 16px;
  color: #47525D;
  font-size: 15px;
  font-weight: 300; }
  .remark-for-calendar .icon-actived-date {
    margin-top: 3px;
    margin-right: 10px;
    color: #2D95F4; }
  .remark-for-calendar .icon-selected-date {
    color: #F05F60;
    margin-top: 3px;
    margin-right: 10px; }

.full-background-block {
  width: calc(100% + 2rem);
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: 10; }

.full-spot-light {
  background-color: black;
  width: calc(100% + 2rem);
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: 11;
  opacity: 0.9; }

.text-full-spot-light {
  top: 20vh;
  margin-left: auto;
  margin-right: auto;
  left: 10%;
  right: 10%;
  position: absolute;
  z-index: 11;
  color: #FF9F00; }
  .text-full-spot-light h3 {
    font-size: 26px; }

.text-full-spot-light-title {
  top: 29vh;
  margin-left: auto;
  margin-right: auto;
  left: 10%;
  right: 10%;
  position: absolute;
  z-index: 11;
  color: #FFFFFF;
  font-size: 20px; }

.selected-logo {
  width: 170px;
  top: 47vh;
  margin-left: auto;
  margin-right: auto;
  left: 10%;
  right: 10%;
  position: absolute;
  z-index: 11;
  color: #FFFFFF; }

.text-full-spot-light-footer {
  top: 68vh;
  margin-left: auto;
  margin-right: auto;
  right: 0;
  left: 0;
  text-align: center;
  position: absolute;
  z-index: 11;
  color: #FFFFFF; }

.bank-edit-button {
  margin: 0px 35%; }

.Calendar__day.-ltr.-today.disabled_provider_date {
  background-color: #F05F60;
  color: #ffffff !important;
  border: #F05F60 solid 1px;
  border-radius: 50%; }

.Calendar__day.-ltr.disabled_provider_date {
  background-color: #F05F60;
  color: #ffffff;
  border: #F05F60 solid 1px;
  border-radius: 50%; }

.Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after {
  content: '';
  position: absolute;
  bottom: 9px;
  display: block;
  width: 1em;
  height: 1.1px;
  background: #000;
  left: 50%;
  opacity: 0.5;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  transition: 0.2s; }

.Calendar__day.-ltr.disabled_provider_date.-selected {
  background-color: #F84B60 !important;
  color: #ffffff !important;
  border: #F84B60 solid 1px !important; }

.content-calendar-page p {
  font-size: 18px; }

.content-calendar-page p, .content-calendar-page h5 {
  padding: 0 16px;
  font-weight: 400; }

.job-time {
  padding: 0 0 16px 40px; }

.header-selected-disabled-date {
  padding: 0 0 0 20px; }

.Calendar {
  max-width: 100vw;
  width: 100%;
  padding-top: 0;
  box-shadow: none; }

.Calendar__weekDays {
  padding: 0;
  color: rgba(0, 0, 0, 0.8);
  font-size: 1.45em; }

.Calendar__header {
  background-color: #E9EFF5;
  margin-bottom: 8px; }

.Calendar__monthText {
  color: #5B8AC3;
  font-size: 18px; }

.Calendar__yearText {
  color: #5B8AC3;
  font-size: 18px; }

.Calendar__monthArrow {
  color: #5B8AC3; }

.Calendar__section.-shown {
  padding: 0; }

.Calendar__day.-ltr {
  width: calc(100% / 10);
  margin: 16px 8px; }

.Calendar__sectionWrapper {
  height: 410px; }

.Calendar__day.-ltr.-weekend {
  z-index: 2; }

.Calendar__section.-hiddenNext {
  z-index: 1;
  margin-left: 26px; }

.Calendar__day.-ltr.active_provider_date.-disabled {
  color: #CECECE !important; }

.active_provider_date:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  color: #2D95F4; }

.active_provider_date.-disabled:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected):hover {
  color: #CECECE !important; }

.active_provider_date:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected):hover {
  color: #2D95F4 !important; }

.Calendar__day.-ltr.disabled_provider_date:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
  background-color: #F05F60;
  color: #ffffff;
  border: #F05F60 solid 1px; }

.Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
  background-color: #ffffff; }

.Calendar__day.active_provider_date:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected):hover {
  background-color: #ffffff; }

.Calendar__day.-ltr.disabled_provider_date.-disabled {
  background-color: #F05F60 !important;
  border: #F05F60 solid 1px;
  border-radius: 50%;
  color: #CECECE !important; }

.calendar-main-page {
  background: #ffffff; }

.alert-calendar .alert-message {
  font-size: 15px;
  text-align: unset; }

.alert-calendar .alert-title {
  font-size: 18px;
  font-weight: 400; }

.alert-calendar .alert-head {
  text-align: unset;
  padding-bottom: 16px; }

.calendar-card-row {
  padding: 0 6px; }

.modal-toolbar {
  --min-height: 56px; }
  .modal-toolbar .page-title {
    font-size: 20px;
    font-weight: 400;
    -webkit-padding-start: 0px;
            padding-inline-start: 0px;
    -webkit-padding-end: 0px;
            padding-inline-end: 0px; }

.child {
  padding-bottom: 16px; }

.text-toast-button {
  position: absolute;
  right: 68px;
  width: 96px;
  color: #FFFFFF; }

.text-toast-button-secondary {
  position: absolute;
  right: 53px;
  width: 96px;
  color: #ffffff; }

.carlendar-page {
  background-color: #ffffff;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover; }

.my-calendar {
  padding: 0;
  background-color: #ffffff; }

.remark-for-calendar {
  padding-left: 16px;
  color: #47525D;
  font-size: 15px;
  font-weight: 300; }
  .remark-for-calendar .icon-actived-date {
    margin-top: 3px;
    margin-right: 10px;
    color: #2D95F4; }
  .remark-for-calendar .icon-selected-date {
    color: #F05F60;
    margin-top: 3px;
    margin-right: 10px; }
  .remark-for-calendar .icon-already-date {
    color: #18CB88;
    margin-top: 3px;
    margin-right: 10px; }

.full-background-block {
  width: calc(100% + 2rem);
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: 10; }

.full-spot-light {
  background-color: black;
  width: calc(100% + 2rem);
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: 11;
  opacity: 0.9; }

.text-full-spot-light {
  top: 20vh;
  margin-left: auto;
  margin-right: auto;
  left: 10%;
  right: 10%;
  position: absolute;
  z-index: 11;
  color: #FF9F00; }
  .text-full-spot-light h3 {
    font-size: 26px; }

.text-full-spot-light-title {
  top: 29vh;
  margin-left: auto;
  margin-right: auto;
  left: 10%;
  right: 10%;
  position: absolute;
  z-index: 11;
  color: #FFFFFF;
  font-size: 20px; }

.selected-logo {
  width: 170px;
  top: 47vh;
  margin-left: auto;
  margin-right: auto;
  left: 10%;
  right: 10%;
  position: absolute;
  z-index: 11;
  color: #FFFFFF; }

.text-full-spot-light-footer {
  top: 68vh;
  margin-left: auto;
  margin-right: auto;
  right: 0;
  left: 0;
  text-align: center;
  position: absolute;
  z-index: 11;
  color: #FFFFFF; }

.bank-edit-button {
  margin: 0px 35%; }

.Calendar__day.-ltr.-today.disabled_provider_date {
  background-color: #F05F60;
  color: #ffffff !important;
  border: #F05F60 solid 1px;
  border-radius: 50%; }

.Calendar__day.-ltr.disabled_provider_date {
  background-color: #F05F60;
  color: #ffffff;
  border: #F05F60 solid 1px;
  border-radius: 50%; }

.Calendar__day.-ltr.disabled_provider_date.-selected {
  background-color: #F84B60 !important;
  color: #ffffff !important;
  border: #F84B60 solid 1px !important; }

.disabled-calendar .Calendar__day.-ltr.-selected {
  background-color: #F84B60 !important;
  color: #ffffff !important;
  border: #F84B60 solid 1px !important; }

.content-calendar-page p {
  font-size: 18px; }

.content-calendar-page p, .content-calendar-page h5 {
  padding: 0 16px;
  font-weight: 400; }

.job-time {
  padding: 0 0 16px 40px; }

.job-time-calendar {
  padding: 0 16px 0 16px; }

.header-selected-disabled-date {
  padding: 0 0 0 20px; }

.Calendar {
  max-width: 100vw;
  width: 100%;
  padding-top: 0;
  box-shadow: none; }

.Calendar__weekDays {
  padding: 0;
  color: rgba(0, 0, 0, 0.8);
  font-size: 1.45em; }

.Calendar__header {
  background-color: #E9EFF5;
  margin-bottom: 8px; }

.Calendar__monthText {
  color: #5B8AC3;
  font-size: 18px; }

.Calendar__yearText {
  color: #5B8AC3;
  font-size: 18px; }

.Calendar__monthArrow {
  color: #5B8AC3; }

.Calendar__section.-shown {
  padding: 0; }

.Calendar__day.-ltr {
  width: calc(100% / 10);
  margin: 16px 8px; }

.Calendar__sectionWrapper {
  height: 360px; }

.Calendar__day.-ltr.-weekend {
  z-index: 2; }

.Calendar__section.-hiddenNext {
  z-index: 1;
  margin-left: 26px; }

.Calendar__day.-ltr.active_provider_date.-disabled {
  color: #CECECE !important; }

.active_provider_date:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  color: #2D95F4; }

.active_provider_date.-disabled:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected):hover {
  color: #CECECE !important; }

.active_provider_date:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected):hover {
  color: #2D95F4 !important; }

.Calendar__day.-ltr.disabled_provider_date:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
  background-color: #F05F60;
  color: #ffffff;
  border: #F05F60 solid 1px; }

.Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
  background-color: #ffffff; }

.Calendar__day.active_provider_date:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected):hover {
  background-color: #ffffff; }

.group_available_active_disable_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background-image: url("/assets/img/dot-mix.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.group_available_active_disable_day.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url("/assets/img/dot-mix.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.pair_available_active_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background-image: url("/assets/img/dot-blue-green.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.pair_available_active_day.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url("/assets/img/dot-blue-green.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.pair_available_disable_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background-image: url("/assets/img/dot-red-green.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.pair_available_disable_day.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url("/assets/img/dot-red-green.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.pair_active_disable_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background-image: url("/assets/img/dot-blue-red.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.pair_active_disable_day.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url("/assets/img/dot-blue-red.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.pair_active_overlap_disable_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background-image: url("/assets/img/dot-blue-red.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important;
  text-decoration: line-through;
  color: #CECECE; }

.pair_active_overlap_disable_day.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url("/assets/img/dot-blue-red.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.pair_available_overlap_active_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background-image: url("/assets/img/dot-blue-green.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important;
  text-decoration: line-through;
  color: #CECECE; }

.pair_available_overlap_active_day.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url("/assets/img/dot-blue-green.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.group_available_active_overlap_disable_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background-image: url("/assets/img/dot-mix.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important;
  text-decoration: line-through;
  color: #CECECE; }

.group_available_active_overlap_disable_day.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url("/assets/img/dot-mix.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.active_day_overlab:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background-image: url("/assets/img/dot-blue.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important;
  text-decoration: line-through;
  color: #CECECE; }

.active_day_overlab.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url("/assets/img/dot-blue.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.active_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background-image: url("/assets/img/dot-blue.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.active_day.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url("/assets/img/dot-blue.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.available_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background-image: url("/assets/img/dot-green.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.available_day.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url("/assets/img/dot-green.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.disable_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background-image: url("/assets/img/red-dot.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.disable_day.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url("/assets/img/red-dot.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.Calendar__day.-ltr.disabled_provider_date.-disabled {
  background-color: #F05F60 !important;
  border: #F05F60 solid 1px;
  border-radius: 50%;
  color: #CECECE !important; }

.calendar-main-page {
  background: #ffffff; }

.alert-calendar .alert-message {
  font-size: 15px;
  text-align: unset; }

.alert-calendar .alert-title {
  font-size: 18px;
  font-weight: 400; }

.alert-calendar .alert-head {
  text-align: unset;
  padding-bottom: 16px; }

.calendar-card-row {
  padding: 0 6px; }

.modal-toolbar {
  --min-height: 56px; }
  .modal-toolbar .page-title {
    font-size: 20px;
    font-weight: 400;
    -webkit-padding-start: 0px;
            padding-inline-start: 0px;
    -webkit-padding-end: 0px;
            padding-inline-end: 0px; }

.child {
  padding-bottom: 16px; }

.text-toast-button {
  position: absolute;
  right: 68px;
  width: 96px;
  color: #FFFFFF; }

.text-toast-button-secondary {
  position: absolute;
  right: 53px;
  width: 96px;
  color: #ffffff; }

.calendar-devide {
  box-sizing: border-box;
  height: 1px;
  width: 100%;
  margin-bottom: 16px;
  border: 1px dashed #CECECE; }

.carlendar-page {
  background-color: #ffffff;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover; }

.my-calendar {
  padding: 0;
  background-color: #ffffff; }

.remark-for-calendar {
  padding-left: 16px;
  color: #47525D;
  font-size: 15px;
  font-weight: 300; }
  .remark-for-calendar .icon-actived-date {
    margin-top: 3px;
    margin-right: 10px;
    color: #2D95F4; }
  .remark-for-calendar .icon-selected-date {
    color: #F05F60;
    margin-top: 3px;
    margin-right: 10px; }

.full-background-block {
  width: calc(100% + 2rem);
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: 10; }

.full-spot-light {
  background-color: black;
  width: calc(100% + 2rem);
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: 11;
  opacity: 0.9; }

.text-full-spot-light {
  top: 20vh;
  margin-left: auto;
  margin-right: auto;
  left: 10%;
  right: 10%;
  position: absolute;
  z-index: 11;
  color: #FF9F00; }
  .text-full-spot-light h3 {
    font-size: 26px; }

.text-full-spot-light-title {
  top: 29vh;
  margin-left: auto;
  margin-right: auto;
  left: 10%;
  right: 10%;
  position: absolute;
  z-index: 11;
  color: #FFFFFF;
  font-size: 20px; }

.selected-logo {
  width: 170px;
  top: 47vh;
  margin-left: auto;
  margin-right: auto;
  left: 10%;
  right: 10%;
  position: absolute;
  z-index: 11;
  color: #FFFFFF; }

.text-full-spot-light-footer {
  top: 68vh;
  margin-left: auto;
  margin-right: auto;
  right: 0;
  left: 0;
  text-align: center;
  position: absolute;
  z-index: 11;
  color: #FFFFFF; }

.bank-edit-button {
  margin: 0px 35%; }

.Calendar__day.-ltr.-today.disabled_provider_date {
  background-color: #F05F60;
  color: #ffffff !important;
  border: #F05F60 solid 1px;
  border-radius: 50%; }

.Calendar__day.-ltr.disabled_provider_date {
  background-color: #F05F60;
  color: #ffffff;
  border: #F05F60 solid 1px;
  border-radius: 50%; }

.Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after {
  content: '';
  position: absolute;
  bottom: 9px;
  display: block;
  width: 1em;
  height: 1.1px;
  background: #000;
  left: 50%;
  opacity: 0.5;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  transition: 0.2s; }

.Calendar__day.-ltr.disabled_provider_date.-selected {
  background-color: #F84B60 !important;
  color: #ffffff !important;
  border: #F84B60 solid 1px !important; }

.content-calendar-page p {
  font-size: 18px; }

.content-calendar-page p, .content-calendar-page h5 {
  padding: 0 16px;
  font-weight: 400; }

.job-time {
  padding: 0 0 16px 40px; }

.header-selected-disabled-date {
  padding: 0 0 0 20px; }

.Calendar {
  max-width: 100vw;
  width: 100%;
  padding-top: 0;
  box-shadow: none; }

.Calendar__weekDays {
  padding: 0;
  color: rgba(0, 0, 0, 0.8);
  font-size: 1.45em; }

.Calendar__header {
  background-color: #E9EFF5;
  margin-bottom: 8px; }

.Calendar__monthText {
  color: #5B8AC3;
  font-size: 18px; }

.Calendar__yearText {
  color: #5B8AC3;
  font-size: 18px; }

.Calendar__monthArrow {
  color: #5B8AC3; }

.Calendar__section.-shown {
  padding: 0; }

.Calendar__day.-ltr {
  width: calc(100% / 10);
  margin: 16px 8px; }

.Calendar__sectionWrapper {
  height: 360px; }

.Calendar__day.-ltr.-weekend {
  z-index: 2; }

.Calendar__section.-hiddenNext {
  z-index: 1;
  margin-left: 26px; }

.Calendar__day.-ltr.active_provider_date.-disabled {
  color: #CECECE !important; }

.active_provider_date:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  color: #2D95F4; }

.active_provider_date.-disabled:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected):hover {
  color: #CECECE !important; }

.active_provider_date:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected):hover {
  color: #2D95F4 !important; }

.Calendar__day.-ltr.disabled_provider_date:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
  background-color: #F05F60;
  color: #ffffff;
  border: #F05F60 solid 1px; }

.Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
  background-color: #ffffff; }

.Calendar__day.active_provider_date:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected):hover {
  background-color: #ffffff; }

.Calendar__day.-ltr.disabled_provider_date.-disabled {
  background-color: #F05F60 !important;
  border: #F05F60 solid 1px;
  border-radius: 50%;
  color: #CECECE !important; }

.calendar-main-page {
  background: #ffffff; }

.alert-calendar .alert-message {
  font-size: 15px;
  text-align: unset; }

.alert-calendar .alert-title {
  font-size: 18px;
  font-weight: 400; }

.alert-calendar .alert-head {
  text-align: unset;
  padding-bottom: 16px; }

.calendar-card-row {
  padding: 0 6px; }

.modal-toolbar {
  --min-height: 56px; }
  .modal-toolbar .page-title {
    font-size: 20px;
    font-weight: 400;
    -webkit-padding-start: 0px;
            padding-inline-start: 0px;
    -webkit-padding-end: 0px;
            padding-inline-end: 0px; }

.child {
  padding-bottom: 16px; }

.text-toast-button {
  position: absolute;
  right: 68px;
  width: 96px;
  color: #FFFFFF; }

.text-toast-button-secondary {
  position: absolute;
  right: 53px;
  width: 96px;
  color: #ffffff; }

.carlendar-page {
  background-color: #ffffff;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover; }

.my-calendar {
  padding: 0;
  background-color: #ffffff; }

.remark-for-calendar {
  padding-left: 16px;
  color: #47525d;
  font-size: 15px;
  font-weight: 300; }
  .remark-for-calendar .icon-actived-date {
    margin-top: 3px;
    margin-right: 10px;
    color: #2d95F4; }
  .remark-for-calendar .icon-selected-date {
    color: #F05F60;
    margin-top: 3px;
    margin-right: 10px; }

.full-background-block {
  width: calc(100% + 2rem);
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: 10; }

.full-spot-light {
  background-color: black;
  width: calc(100% + 2rem);
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: 11;
  opacity: 0.9; }

.text-full-spot-light {
  top: 20vh;
  margin-left: auto;
  margin-right: auto;
  left: 10%;
  right: 10%;
  position: absolute;
  z-index: 11;
  color: #FF9F00; }
  .text-full-spot-light h3 {
    font-size: 26px; }

.text-full-spot-light-title {
  top: 29vh;
  margin-left: auto;
  margin-right: auto;
  left: 10%;
  right: 10%;
  position: absolute;
  z-index: 11;
  color: #FFFFFF;
  font-size: 20px; }

.selected-logo {
  width: 170px;
  top: 47vh;
  margin-left: auto;
  margin-right: auto;
  left: 10%;
  right: 10%;
  position: absolute;
  z-index: 11;
  color: #FFFFFF; }

.text-full-spot-light-footer {
  top: 68vh;
  margin-left: auto;
  margin-right: auto;
  right: 0;
  left: 0;
  text-align: center;
  position: absolute;
  z-index: 11;
  color: #FFFFFF; }

.bank-edit-button {
  margin: 0px 35%; }

.Calendar__day.-ltr.-today.disabled_provider_date {
  background-color: #F05F60;
  color: #ffffff !important;
  border: #F05F60 solid 1px;
  border-radius: 50%; }

.Calendar__day.-ltr.disabled_provider_date {
  background-color: #F05F60;
  color: #ffffff;
  border: #F05F60 solid 1px;
  border-radius: 50%; }

.Calendar__day.-ltr.available_provider_date {
  background-color: #18CB88 !important;
  border: #18CB88 solid 1px;
  border-radius: 50%;
  color: #ffffff !important; }

.Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after {
  content: '';
  position: absolute;
  bottom: 9px;
  display: block;
  width: 1em;
  height: 1.1px;
  background: #000;
  left: 50%;
  opacity: 0.5;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  transition: 0.2s; }

.Calendar__day.-ltr.disabled_provider_date.-selected {
  background-color: #F84B60 !important;
  color: #ffffff !important;
  border: #F84B60 solid 1px !important; }

.Calendar__day.-ltr.-selected {
  border: 1px solid #1B4170;
  background: #ffffff;
  color: #47525d; }

.content-calendar-page p {
  font-size: 18px; }

.content-calendar-page p, .content-calendar-page h5 {
  padding: 0 16px;
  font-weight: 400; }

.Calendar {
  max-width: 100vw;
  width: 100%;
  padding-top: 0;
  box-shadow: none; }

.Calendar__weekdays {
  padding: 0;
  color: rgba(0, 0, 0, 0.8);
  font-size: 1.45em; }

.Calendar__header {
  background-color: #E9EFF5;
  margin-bottom: 8px; }

.Calendar__monthText {
  color: #5B8AC3;
  font-size: 18px; }

.Calendar__yearText {
  color: #5B8AC3;
  font-size: 18px; }

.Calendar__monthArrow {
  color: #5B8AC3; }

.Calendar__section.-shown {
  padding: 0; }

.Calendar__day.-ltr {
  width: calc(100% / 10);
  margin: 16px 8px; }

.Calendar__sectionWrapper {
  height: 410px; }

.Calendar__day.-ltr.-weekend {
  z-index: 2; }

.Calendar__section.-hiddenNext {
  z-index: 1;
  margin-left: 26px; }

.Calendar__day.-ltr.active_provider_date.-disabled {
  color: #CECECE !important; }

.active_provider_date:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  color: #2d95F4; }

.active_provider_date.-disabled:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected):hover {
  color: #CECECE !important; }

.active_provider_date:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected):hover {
  color: #2d95F4 !important; }

.Calendar__day.-ltr.disabled_provider_date:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
  background-color: #F05F60;
  color: #ffffff;
  border: #F05F60 solid 1px; }

.Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
  background-color: #ffffff; }

.Calendar__day.active_provider_date:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected):hover {
  background-color: #ffffff; }

.group_available_active_disable_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background-image: url("/assets/img/dot-mix.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.group_available_active_disable_day.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url("/assets/img/dot-mix.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.pair_available_active_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background-image: url("/assets/img/dot-blue-green.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.pair_available_active_day.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url("/assets/img/dot-blue-green.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.pair_available_disable_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background-image: url("/assets/img/dot-red-green.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.pair_available_disable_day.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url("/assets/img/dot-red-green.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.pair_active_disable_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background-image: url("/assets/img/dot-blue-red.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.pair_active_disable_day.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url("/assets/img/dot-blue-red.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.active_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background-image: url("/assets/img/dot-blue.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.active_day.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url("/assets/img/dot-blue.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.available_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background-image: url("/assets/img/dot-green.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.available_day.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url("/assets/img/dot-green.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.disable_day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background-image: url("/assets/img/red-dot.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.disable_day.-selected {
  border: 1px solid #1B4170;
  border-radius: 50%;
  background-image: url("/assets/img/red-dot.png") !important;
  background-size: contain !important;
  background-position: 40% 100% !important; }

.Calendar__day.-ltr.disabled_provider_date.-disabled {
  background-color: #F05F60 !important;
  border: #F05F60 solid 1px;
  border-radius: 50%;
  color: #CECECE !important; }

.calendar-main-page {
  background: #ffffff; }

.alert-calendar .alert-message {
  font-size: 15px;
  text-align: unset; }

.alert-calendar .alert-title {
  font-size: 18px;
  font-weight: 400; }

.alert-calendar .alert-head {
  text-align: unset;
  padding-bottom: 16px; }

.calendar-card-row {
  padding: 0 6px; }

.modal-toolbar {
  --min-height: 56px; }
  .modal-toolbar .page-title {
    font-size: 20px;
    font-weight: 400;
    -webkit-padding-start: 0px;
            padding-inline-start: 0px;
    -webkit-padding-end: 0px;
            padding-inline-end: 0px; }

.child {
  padding-bottom: 16px; }

.text-toast-button {
  position: absolute;
  right: 68px;
  width: 96px;
  color: #FFFFFF; }

.text-toast-button-secondary {
  position: absolute;
  right: 53px;
  width: 96px;
  color: #ffffff; }

.calendar-devide {
  box-sizing: border-box;
  height: 1px;
  width: 100%;
  margin-bottom: 16px;
  border: 1px dashed #CECECE; }

.job-card-container {
  --ion-grid-padding: 0; }
  .job-card-container ion-col {
    --ion-grid-column-padding: 0; }
  .job-card-container .row-flex-end {
    justify-content: flex-end; }
    .job-card-container .row-flex-end .service-icon, .job-card-container .row-flex-end .call-icon, .job-card-container .row-flex-end .message-icon {
      width: 50px;
      height: 50px; }
    .job-card-container .row-flex-end .payout {
      margin: 8px 1px 0 0;
      font-size: 20px; }

.my-job-card {
  margin: 16px;
  padding: 16px;
  border: #FFFFFF solid 1px;
  border-radius: 5px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2); }

.my-job-card:last-child {
  margin: 16px 16px 80px 16px; }

.calendar-action-text {
  font-size: 15px;
  font-weight: 400; }

.delete-job {
  color: red; }

.available-job {
  color: #18CB88; }

.camera-input-basic__container {
  position: relative; }
  .camera-input-basic__container .camera-input-basic__preview {
    width: 168px;
    height: 168px;
    margin: 0 auto;
    background-color: var(--ion-color-light-tint); }
  .camera-input-basic__container .camera-input-basic__edit-button {
    position: absolute;
    bottom: 0;
    right: 29%;
    border-radius: 50%;
    font-size: 24px;
    padding: 6px;
    background-color: var(--ion-color-light-shade); }

.date-picker-input-container {
  border-radius: 5px;
  --padding-start: 0;
  border: 1px solid var(--ion-color-medium); }

.profile-form-container {
  padding: 16px;
  background-color: white; }

.refer-friend-page-container {
  background: white; }

.refer-friend-header-row {
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 24px; }
  .refer-friend-header-row .refer-friend-header-image {
    height: 176px;
    min-width: 290px; }

.refer-friend-content-container {
  margin-right: 16px;
  margin-left: 16px;
  color: #47525d; }
  .refer-friend-content-container .refer-friend-content-header-row {
    margin-bottom: 16px;
    justify-content: center; }
    .refer-friend-content-container .refer-friend-content-header-row .refer-friend-content-header-text {
      font-weight: 500;
      font-size: 20px;
      text-align: center; }
  .refer-friend-content-container .refer-friend-content-row {
    margin-bottom: 32px; }
    .refer-friend-content-container .refer-friend-content-row .refer-friend-content-text {
      font-size: 16px; }

.refer-friend-info-button-one {
  margin-bottom: 16px; }

.refer-friend-universal-url-row {
  width: 100%; }
  .refer-friend-universal-url-row .refer-friend-universal-url-btn {
    width: 100%; }

.refer-friend-footer {
  width: 100%; }
  .refer-friend-footer .refer-friend-universal-url-btn {
    width: 100%;
    margin-left: 16px;
    margin-right: 16px; }

.info-button-container {
  min-height: 90px;
  width: 100%;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .info-button-container .info-button-heading {
    font-size: 20px; }
  .info-button-container .info-button-row {
    margin-left: 24px;
    color: white; }
    .info-button-container .info-button-row .info-button-content {
      font-size: 16px; }
    .info-button-container .info-button-row .info-button-data {
      font-size: 16px; }

.refer-friend-pending-page-container {
  min-height: 100%; }

.refer-friend-pending-page-header {
  margin-bottom: 17px; }

.refer-friend-pending-page-content-row {
  margin-left: 16px;
  margin-right: 16px;
  justify-content: center; }
  .refer-friend-pending-page-content-row .refer-friend-pending-page-content-text {
    font-size: 18px; }

.refer-friend-pending-page-btn-tab-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: -11px; }

.refer-friend-pending-footer {
  width: 100%; }
  .refer-friend-pending-footer .refer-friend-pending-page-btn {
    width: 100%;
    margin-left: 16px;
    margin-right: 16px; }


.referral-card-container {
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
  border-top: 2px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 0px; }

.referral-card-avatar {
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 6px; }
  .referral-card-avatar .referral-card-avatar-img {
    width: 69px;
    height: 69px; }

.referral-card-name-data-col {
  margin-left: 20px; }

.referral-card-name-text {
  margin-top: 0px;
  font-size: 16px;
  margin-bottom: 4px;
  color: #47525D; }

.referral-card-info-row {
  font-size: 16px;
  margin-bottom: 2px; }
  .referral-card-info-row .registering {
    color: #F5A623; }
  .referral-card-info-row .approved {
    color: #2D95F4; }
  .referral-card-info-row .completed {
    color: #18CB88; }

.referral-card-date-text {
  font-size: 13px;
  color: #7E7E7E; }

.refer-friend-tab-button {
  display: flex;
  font-size: 16px;
  font-weight: 400;
  flex-grow: 1;
  z-index: 20; }

.active {
  color: #47525D;
  border-bottom: 3px solid #1B4170; }

.inactive {
  color: #AAAAAA; }

.setting-page-header {
  position: relative;
  background-color: var(--ion-color-primary);
  margin-bottom: 71px; }
  .setting-page-header .setting-page-header__provider {
    --background: transparent;
    padding: 8px 8px 58px 8px; }
    .setting-page-header .setting-page-header__provider .setting-page-header__avatar-container {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: center; }
    .setting-page-header .setting-page-header__provider .setting-page-header__provider__avatar {
      width: 57px;
      height: 57px; }
    .setting-page-header .setting-page-header__provider .setting-page-header__provider__name {
      font-size: 20px; }
    .setting-page-header .setting-page-header__provider .setting-page-header__provider__rating {
      font-size: 15px; }
    .setting-page-header .setting-page-header__provider .setting-page-header__provider__point {
      font-size: 18px; }
  .setting-page-header .setting-page-row__provider__rating {
    margin-bottom: -16px; }
  .setting-page-header .icon-rating {
    height: 21px;
    margin: 0 6px 0 12px; }
  .setting-page-header .wallet-balance-card {
    position: absolute;
    bottom: -55px;
    height: 111px;
    margin: 0 8px;
    width: -webkit-fill-available;
    --box-shadow: 0px 3px 5px 0px rgba(82,82,82,0.5); }
    .setting-page-header .wallet-balance-card .wallet-balance-card__content-wrapper {
      width: 100%;
      --background: transparent;
      --padding-start: 0px; }
      .setting-page-header .wallet-balance-card .wallet-balance-card__content-wrapper .wallet-balance-card__label {
        display: block;
        font-weight: 400;
        margin-bottom: 16px; }
      .setting-page-header .wallet-balance-card .wallet-balance-card__content-wrapper .wallet-balance-card__amount {
        display: block;
        font-size: 28px; }
      .setting-page-header .wallet-balance-card .wallet-balance-card__content-wrapper .wallet-balance-card__icon {
        height: 31px; }

.setting-menu-list {
  margin-bottom: 16px; }

.profile-icon-image {
  width: 17px;
  margin: 0 20px 0 4px; }

.address-icon-image {
  width: 17px;
  margin: 0 20px 0 4px; }

.wallet-icon-image, .contact-icon-image {
  width: 22px;
  margin: 0 17px 0 2px; }

.setting-new-item-label {
  max-width: -webkit-max-content;
  max-width: max-content;
  font-weight: 300;
  margin: 16px 0 16px 0;
  padding: 0; }

.setting-item-label {
  font-weight: 300;
  margin: 16px 0 16px 0; }

.setting-item-label-text-right {
  text-align: right; }

.setting-item-label-right {
  display: flex;
  text-align: right;
  align-items: center;
  justify-content: flex-end; }

.new-badge {
  margin: 0 6px;
  padding: 4px 12px;
  font-weight: 500; }

.change-language-icon, .refer-friend-icon {
  color: #808080; }

.logout-button {
  text-align: center;
  --color: var(--ion-color-danger) !important; }

.workforce-small-logo {
  width: 100px;
  margin: 4px auto 8px auto; }

.app-version {
  display: block;
  text-align: center;
  font-size: 13px; }

.setting-page.toast {
  --background: var(--ion-color-secondary, #3171e0);
  --color: var(--ion-color-secondary-contrast, #fff); }

.setting-page.bottom-sheet .modal-wrapper {
  -webkit-transform: translate3d(0, 40%, 0);
          transform: translate3d(0, 40%, 0); }

ion-list.bottom-drawer ion-item {
  --min-height: 70px; }

.privacy-icon, .terms-icon, .support-icon {
  fill: #808080;
  height: 23px; }

.terms-icon {
  margin-left: 4px;
  margin-right: 16px; }

.wallet-transaction__list-item {
  --padding-top: 16px;
  --padding-end: 16px;
  --padding-bottom: 16px;
  --padding-start: 16px;
  --inner-padding-top: 0;
  --inner-padding-end: 0;
  --inner-padding-bottom: 0;
  --inner-padding-start: 0; }
  .wallet-transaction__list-item .wallet-transaction__inner-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%; }
    .wallet-transaction__list-item .wallet-transaction__inner-container h3 {
      margin: 0 0 8px 0;
      font-size: 18px;
      font-weight: 400; }
    .wallet-transaction__list-item .wallet-transaction__inner-container p {
      margin: 0;
      font-weight: 300; }
    .wallet-transaction__list-item .wallet-transaction__inner-container > div:nth-child(1) {
      flex: 1 1; }
      .wallet-transaction__list-item .wallet-transaction__inner-container > div:nth-child(1) > p {
        font-size: 15px; }
      .wallet-transaction__list-item .wallet-transaction__inner-container > div:nth-child(1) .number > p {
        font-weight: 500; }
    .wallet-transaction__list-item .wallet-transaction__inner-container > div:nth-child(2) {
      text-align: right;
      width: 104px; }
      .wallet-transaction__list-item .wallet-transaction__inner-container > div:nth-child(2) > h3.wt-amount--orange {
        color: #F5A623; }
      .wallet-transaction__list-item .wallet-transaction__inner-container > div:nth-child(2) > h3.wt-amount--green {
        color: var(--ion-color-success); }
        .wallet-transaction__list-item .wallet-transaction__inner-container > div:nth-child(2) > h3.wt-amount--green::before {
          content: '+ '; }
      .wallet-transaction__list-item .wallet-transaction__inner-container > div:nth-child(2) > h3.wt-amount--red {
        color: #D0021B; }
        .wallet-transaction__list-item .wallet-transaction__inner-container > div:nth-child(2) > h3.wt-amount--red::before {
          content: '- '; }
      .wallet-transaction__list-item .wallet-transaction__inner-container > div:nth-child(2) > h3.wt-amount--grey {
        color: var(--ion-color-medium);
        text-decoration: line-through; }
      .wallet-transaction__list-item .wallet-transaction__inner-container > div:nth-child(2) > p {
        font-size: 13px;
        color: var(--ion-color-success); }
        .wallet-transaction__list-item .wallet-transaction__inner-container > div:nth-child(2) > p.wt__has-fee {
          color: var(--ion-color-danger); }
      .wallet-transaction__list-item .wallet-transaction__inner-container > div:nth-child(2) > ion-icon.wt__active {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg); }
    .wallet-transaction__list-item .wallet-transaction__inner-container > div:nth-child(3) {
      flex: 100% 1; }
      .wallet-transaction__list-item .wallet-transaction__inner-container > div:nth-child(3).wt__active {
        display: block; }
      .wallet-transaction__list-item .wallet-transaction__inner-container > div:nth-child(3).wt__inactive {
        display: none; }
      .wallet-transaction__list-item .wallet-transaction__inner-container > div:nth-child(3) > .wt-details__container > p, .wallet-transaction__list-item .wallet-transaction__inner-container > div:nth-child(3) > .wt-details__container div {
        font-size: 13px;
        font-weight: 300;
        color: #828282; }
      .wallet-transaction__list-item .wallet-transaction__inner-container > div:nth-child(3) > .wt-details__container > .wt-details__description {
        font-size: 15px; }
      .wallet-transaction__list-item .wallet-transaction__inner-container > div:nth-child(3) > .wt-details__container > .wt-details__remark, .wallet-transaction__list-item .wallet-transaction__inner-container > div:nth-child(3) > .wt-details__container .wt-details__remark--danger {
        margin-left: 8px; }
      .wallet-transaction__list-item .wallet-transaction__inner-container > div:nth-child(3) > .wt-details__container > .wt-details__remark--danger {
        color: var(--ion-color-danger); }
      .wallet-transaction__list-item .wallet-transaction__inner-container > div:nth-child(3) > .wt-details__container > .wt-details__remark > .wt-details__remark--bank {
        display: flex;
        align-items: center;
        padding-top: 4px; }
        .wallet-transaction__list-item .wallet-transaction__inner-container > div:nth-child(3) > .wt-details__container > .wt-details__remark > .wt-details__remark--bank > img {
          margin-right: 8px; }
      .wallet-transaction__list-item .wallet-transaction__inner-container > div:nth-child(3) > .wt-details__container > .wt-details__remark > .wt-details__remark--account--name {
        padding: 4px 0;
        line-height: 23px; }

.wallet-page__page-header > ion-toolbar {
  --border-width: 0 !important; }

.wallet-page-header__backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  background-color: var(--ion-color-primary); }

.wallet-page-content__container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  .wallet-page-content__container .wallet-page__balance-card {
    background-color: var(--ion-color-secondary);
    color: white;
    margin: 16px;
    padding: 16px 9px 12px 9px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    display: flex;
    flex-wrap: wrap; }
    .wallet-page-content__container .wallet-page__balance-card .wallet-page__available-balance,
    .wallet-page-content__container .wallet-page__balance-card .wallet-page__total-balance {
      flex: 50% 1;
      text-align: center; }
      .wallet-page-content__container .wallet-page__balance-card .wallet-page__available-balance > label,
      .wallet-page-content__container .wallet-page__balance-card .wallet-page__total-balance > label {
        font-size: 15px; }
      .wallet-page-content__container .wallet-page__balance-card .wallet-page__available-balance > p,
      .wallet-page-content__container .wallet-page__balance-card .wallet-page__total-balance > p {
        font-size: 26px;
        font-weight: 500;
        margin: 4px 0 0 0; }
    .wallet-page-content__container .wallet-page__balance-card .wallet-page__available-balance {
      border-right: 1px solid white; }
    .wallet-page-content__container .wallet-page__balance-card .wallet-page__processing-balance {
      flex: 100% 1;
      margin-top: 16px; }
      .wallet-page-content__container .wallet-page__balance-card .wallet-page__processing-balance > p {
        font-size: 18px;
        margin: 0; }
      .wallet-page-content__container .wallet-page__balance-card .wallet-page__processing-balance > label {
        font-size: 13px;
        color: #F8BE0C; }
    .wallet-page-content__container .wallet-page__balance-card .wallet-page__updated-at {
      flex: 100% 1;
      font-size: 11px;
      font-weight: 300;
      margin: 12px 0 0 0;
      text-align: center; }

.wallet-withdrawal-error-page .content {
  padding: calc((100vh - 56px - 118.8px) / 2) 0;
  text-align: center; }
  .wallet-withdrawal-error-page .content p {
    font-size: 17px;
    line-height: 25px;
    margin: 0; }
  .wallet-withdrawal-error-page .content ion-button {
    --padding-top: 10px;
    --padding-bottom: 10px;
    --padding-start: 10px;
    --padding-end: 10px;
    margin: 24px 0 0 0; }
    .wallet-withdrawal-error-page .content ion-button ion-text {
      font-size: 17px;
      margin-left: 10px; }

.bank-account-select__label {
  --inner-padding-bottom: 0;
  --inner-padding-end: 0;
  --inner-padding-start: 0;
  --inner-padding-top: 0;
  --padding-bottom: 0;
  --padding-end: 0;
  --padding-start: 0;
  --padding-top: 0; }
  .bank-account-select__label > ion-avatar {
    margin-right: 12px; }
  .bank-account-select__label > ion-label > h2 {
    font-size: 18px;
    color: var(--ion-color-secondary); }
  .bank-account-select__label > ion-label > h3 {
    font-size: 15px; }

.wallet-withdrawal-form {
  padding: 16px;
  height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .wallet-withdrawal-form .wallet-withdrawal-form__title {
    font-size: 18px; }
    .wallet-withdrawal-form .wallet-withdrawal-form__title > img {
      height: 20px;
      margin: 0 8px -4px 8px; }
  .wallet-withdrawal-form .wallet-withdrawal-form__balance {
    font-size: 15px;
    font-weight: 300;
    margin: 8px 0 16px 0; }
    .wallet-withdrawal-form .wallet-withdrawal-form__balance > span {
      color: var(--ion-color-secondary);
      margin-left: 16px;
      font-weight: 500; }
  .wallet-withdrawal-form > ion-label {
    font-size: 18px; }
  .wallet-withdrawal-form .withdrawal-remark__label--grey {
    font-size: 18px;
    margin-bottom: 8px;
    display: block; }
  .wallet-withdrawal-form .withdrawal-remark--grey {
    margin: 0 0 20px 0;
    font-size: 15px;
    font-weight: 300; }
  .wallet-withdrawal-form .withdrawal-remark--red {
    margin: 0 0 16px 0;
    color: #D0021B;
    font-size: 18px;
    font-weight: 500; }

.img-slide__container {
  background-color: var(--ion-color-dark);
  height: 100%; }
  .img-slide__container .img-slide__pagination-chip {
    --background: rgba(82, 82, 82, 0.65);
    --color: var(--ion-color-light);
    width: 68px;
    --border-radius: 32px; }

.form-container {
  --ion-grid-padding: 0; }
  .form-container .forget-password-row {
    justify-content: flex-end; }
  .form-container .sign-in-button-col {
    --ion-grid-column-padding: 0; }

.typo-lheader {
  color: #000000;
  font-size: 24px;
  font-weight: 500; }

.logo-container {
  width: 140px; }

.sign-in-with-phone-number-form-container .phone-number-input {
  flex-grow: 1;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 0px 5px 0px 0px !important;
  font-size: 18px !important;
  height: 100% !important;
  font-family: kanit;
  padding-left: 10px !important; }

.sign-in-with-phone-number-form-container .password-input {
  margin-bottom: 8px; }
  .sign-in-with-phone-number-form-container .password-input .password-field {
    font-family: kanit; }

.sign-in-with-phone-number-form-container ion-item {
  border-radius: 0px 0px 5px 5px;
  border-left: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-right: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-top: 0px; }

.sign-in-with-phone-number-form-container .send-otp-small-paragraph-1 {
  margin-bottom: 12px; }

.sign-in-with-phone-number-form-container .send-otp-small-paragraph-2 {
  margin-bottom: 12px; }
  .sign-in-with-phone-number-form-container .send-otp-small-paragraph-2 .link {
    color: #2d95f4;
    text-decoration: underline; }

.sign-in-with-phone-number-form-container .input-container {
  display: flex;
  height: 53px; }

.sign-in-with-phone-number-form-container .country-box-login {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  height: 100%;
  border-top-left-radius: 5px; }
  .sign-in-with-phone-number-form-container .country-box-login img {
    margin-left: 10px;
    margin-right: 10px;
    height: 25px; }
  .sign-in-with-phone-number-form-container .country-box-login .country-number {
    margin-right: 5px; }

.sign-in-page {
  --padding-top: 6px;
  --padding-bottom: 6px;
  --padding-start: 6px;
  --padding-end: 6px; }
  .sign-in-page h1,
  .sign-in-page p {
    margin: 0; }
  .sign-in-page .sign-in-header-section {
    display: flex;
    align-items: center; }
    .sign-in-page .sign-in-header-section .header-text {
      margin-right: 4rem; }
  .sign-in-page .grid-container {
    height: 100%;
    display: flex;
    max-width: 400px;
    padding-top: var(--ion-safe-area-top); }
    .sign-in-page .grid-container .sign-in-with-phone-number-container {
      margin-bottom: 20px; }
    .sign-in-page .grid-container .line-login-button {
      --background: #00c300;
      --background-activated: #00b300;
      --background-focused: #00e000;
      --background-hover: #00e000;
      --padding-start: 0px;
      --padding-end: 30px;
      height: 40px;
      margin-bottom: 12px; }
      .sign-in-page .grid-container .line-login-button .line-login-button__icon {
        height: 100%;
        border-right: 1px solid #00b300; }
      .sign-in-page .grid-container .line-login-button:hover .line-login-button__icon {
        border-right: 1px solid #00c900; }
      .sign-in-page .grid-container .line-login-button .line-login-button__text {
        width: 100%; }
    .sign-in-page .grid-container .facebook-sign-in-button {
      --background: #4968ad;
      height: 40px;
      text-align: center;
      margin-bottom: 12px;
      --padding-end: 30px; }
      .sign-in-page .grid-container .facebook-sign-in-button .facebook-sign-in-button__icon {
        font-size: 32px; }
      .sign-in-page .grid-container .facebook-sign-in-button .facebook-sign-in-button__text {
        width: 100%; }
    .sign-in-page .grid-container .apple-sign-in-button {
      --border-radius: 4px;
      --border-width: 1px;
      --border-style: solid;
      --border-color: black;
      --padding-start: 0;
      --padding-end: 30px;
      --background-hover: rgb(70, 67, 67);
      --background: black;
      height: 40px;
      text-align: center;
      margin-bottom: 12px; }
      .sign-in-page .grid-container .apple-sign-in-button .apple-sign-in-button__icon {
        height: 44px;
        width: 44px; }
      .sign-in-page .grid-container .apple-sign-in-button .apple-sign-in-button__text {
        width: 100%;
        color: white; }
    .sign-in-page .grid-container .policy-paragraph {
      text-align: center;
      margin-bottom: 8px; }
    .sign-in-page .grid-container .sign-in-sign-up-row {
      display: flex;
      justify-content: center; }
    .sign-in-page .grid-container .blue-underline {
      cursor: pointer;
      color: #2d95f4;
      text-decoration: underline; }
    .sign-in-page .grid-container .bold {
      font-weight: 600; }
    .sign-in-page .grid-container .terms-and-conditions-text {
      font-size: 13px;
      display: block;
      padding: 0 10px; }
    .sign-in-page .grid-container .register-now-text {
      display: block;
      text-align: center;
      margin: 16px auto; }
    .sign-in-page .grid-container .button-divider {
      display: flex;
      padding: 0 8px;
      margin: 16px 0; }
      .sign-in-page .grid-container .button-divider .text {
        margin: 0 10px; }
      .sign-in-page .grid-container .button-divider .line {
        flex: 25% 1;
        border-bottom: 1px solid #989aa2;
        -webkit-transform: translateY(-45%);
                transform: translateY(-45%); }

.alert-button.ion-focusable.ion-activatable.sc-ion-alert-ios:last-child.fw-normal {
  font-weight: normal; }

.region-select-field-label, .services-select-field-label {
  display: block;
  margin-bottom: 6px; }

.category-name-label {
  font-size: 13px !important;
  font-weight: 300; }

.region-select-container {
  border: 1px solid var(--ion-color-medium);
  border-radius: 5px;
  padding-right: 12px;
  margin-bottom: 24px; }

.service-list-container {
  display: flex;
  flex-wrap: wrap; }
  .service-list-container .service-card-container {
    width: calc((100% - 36px) / 3);
    margin: 10px 6px;
    --padding-start: 0;
    --inner-padding-end: 0;
    --background-activated: transparent;
    --background-hover: transparent; }
    .service-list-container .service-card-container .service-card-thumbnail {
      width: 100%;
      height: 90px; }
      .service-list-container .service-card-container .service-card-thumbnail .service-card-img {
        border-radius: 6px;
        border: 3px solid var(--ion-color-light-tint); }
      .service-list-container .service-card-container .service-card-thumbnail .service-card-img--selected {
        border: 3px solid var(--ion-color-secondary-tint); }
    .service-list-container .service-card-container .service-name-label {
      white-space: normal; }

.sign-up-details-form-container {
  padding: 16px;
  background-color: white; }
  .sign-up-details-form-container .sign-up-details-form-submit-button {
    margin-bottom: 16px; }

.sign-up-form-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.sign-up-page .grid-container {
  min-height: 100%;
  display: flex;
  align-items: top;
  max-width: 400px;
  padding-top: 10px; }
  .sign-up-page .grid-container .line-login-button {
    --background: #00c300;
    --background-activated: #00b300;
    --background-focused: #00e000;
    --background-hover: #00e000;
    --padding-start: 0px;
    --padding-end: 30px;
    height: 40px;
    margin-bottom: 16px; }
    .sign-up-page .grid-container .line-login-button .line-login-button__icon {
      height: 100%;
      border-right: 1px solid #00b300; }
    .sign-up-page .grid-container .line-login-button:hover .line-login-button__icon {
      border-right: 1px solid #00c900; }
    .sign-up-page .grid-container .line-login-button .line-login-button__text {
      width: 100%; }
  .sign-up-page .grid-container .facebook-sign-in-button {
    --background: #4968ad;
    height: 40px;
    text-align: center;
    margin-bottom: 16px;
    --padding-end: 30px; }
    .sign-up-page .grid-container .facebook-sign-in-button .facebook-sign-in-button__icon {
      font-size: 32px; }
    .sign-up-page .grid-container .facebook-sign-in-button .facebook-sign-in-button__text {
      width: 100%; }
  .sign-up-page .grid-container .apple-sign-in-button {
    --background: black;
    --border-radius: 4px;
    --border-width: 1px;
    --border-style: solid;
    --border-color: white;
    --padding-start: 0;
    --padding-end: 30px;
    --background-activated: #black;
    height: 40px;
    text-align: center;
    margin-bottom: 16px; }
    .sign-up-page .grid-container .apple-sign-in-button .apple-sign-in-button__icon {
      height: 100%;
      font-size: 32px; }
    .sign-up-page .grid-container .apple-sign-in-button .apple-sign-in-button__text {
      width: 100%;
      color: white; }
  .sign-up-page .grid-container .button-divider {
    display: flex;
    padding: 0 8px;
    margin: 5px 0; }
    .sign-up-page .grid-container .button-divider .text {
      margin: 0 10px; }
    .sign-up-page .grid-container .button-divider .line {
      flex: 25% 1;
      border-bottom: 1px solid #989aa2;
      -webkit-transform: translateY(-45%);
              transform: translateY(-45%); }

.sign-up-page-core-layout .sign-up-page {
  --padding-top: 6px;
  --padding-bottom: 6px;
  --padding-start: 6px;
  --padding-end: 6px;
  background-color: #f7f7f7; }
  .sign-up-page-core-layout .sign-up-page h1,
  .sign-up-page-core-layout .sign-up-page p {
    margin: 0; }
  .sign-up-page-core-layout .sign-up-page .sign-up-header-section {
    align-items: center;
    align-items: center;
    margin-bottom: 14px; }
    .sign-up-page-core-layout .sign-up-page .sign-up-header-section .header-text {
      margin-right: 4rem; }
  .sign-up-page-core-layout .sign-up-page .sign-up-send-otp-form {
    margin-bottom: 20px; }
  .sign-up-page-core-layout .sign-up-page .line-login-button {
    --background: #00c300;
    --background-activated: #00b300;
    --background-focused: #00e000;
    --background-hover: #00e000;
    --padding-start: 0px;
    --padding-end: 30px;
    height: 40px;
    margin-bottom: 12px; }
    .sign-up-page-core-layout .sign-up-page .line-login-button .line-login-button__icon {
      height: 100%;
      border-right: 1px solid #00b300; }
    .sign-up-page-core-layout .sign-up-page .line-login-button:hover .line-login-button__icon {
      border-right: 1px solid #00c900; }
    .sign-up-page-core-layout .sign-up-page .line-login-button .line-login-button__text {
      width: 100%; }
  .sign-up-page-core-layout .sign-up-page .facebook-sign-in-button {
    --background: #4968ad;
    height: 40px;
    text-align: center;
    margin-bottom: 12px;
    --padding-end: 30px; }
    .sign-up-page-core-layout .sign-up-page .facebook-sign-in-button .facebook-sign-in-button__icon {
      font-size: 32px; }
    .sign-up-page-core-layout .sign-up-page .facebook-sign-in-button .facebook-sign-in-button__text {
      width: 100%; }
  .sign-up-page-core-layout .sign-up-page .apple-sign-in-button {
    --border-radius: 4px;
    --border-width: 1px;
    --border-style: solid;
    --border-color: black;
    --padding-start: 0;
    --padding-end: 30px;
    --background-hover: rgb(70, 67, 67);
    height: 40px;
    text-align: center;
    margin-bottom: 12px; }
  .sign-up-page-core-layout .sign-up-page .sign-up-sign-in-row {
    display: flex;
    justify-content: center; }
  .sign-up-page-core-layout .sign-up-page .blue-underline {
    cursor: pointer;
    color: #2d95f4;
    text-decoration: underline; }
  .sign-up-page-core-layout .sign-up-page .bold {
    font-weight: 600; }

.sign-up-page-core-layout .policy-paragraph {
  text-align: center;
  margin-bottom: 8px; }

.typo-small-paragraph {
  color: #000000;
  font-size: 15px;
  margin-bottom: 18px;
  font-weight: lighter; }

.pending-message-container {
  background-color: #1b4170;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 16px;
  height: 100%; }
  .pending-message-container .header-container {
    margin-bottom: 24px; }
  .pending-message-container .img-container {
    margin-bottom: 24px; }
  .pending-message-container .spacer {
    margin-bottom: 4px; }
  .pending-message-container .we-will-contact-you {
    margin-bottom: 8px; }
  .pending-message-container .line-add-friend-button {
    --background: #00c300;
    --background-activated: #00b300;
    --background-focused: #00e000;
    --background-hover: #00e000;
    --padding-end: 2px;
    --padding-start: 2px;
    max-width: 150px;
    margin-bottom: 12px; }
    .pending-message-container .line-add-friend-button img {
      height: 100%; }
    .pending-message-container .line-add-friend-button .line-add-friend-button__text {
      padding: 0 8px; }
  .pending-message-container .button-container {
    margin-top: auto; }
  .pending-message-container .sign-up-pending-back-button {
    --background: #2d95f4; }

.typo-header {
  color: #252b33;
  font-size: 20px;
  font-weight: 500; }

.white {
  color: #fff; }

.typo-subheader {
  color: #222222;
  font-size: 18px;
  line-height: 18px; }

.white {
  color: #fff; }

.set-password-form-container {
  --ion-grid-padding: 0; }
  .set-password-form-container p {
    margin: 0; }
  .set-password-form-container .password-col {
    margin-bottom: 8px; }
  .set-password-form-container .re-password-col {
    margin-bottom: 14px; }
  .set-password-form-container .paragraph-col {
    margin-bottom: 12px; }
  .set-password-form-container .forget-password-row {
    justify-content: flex-end; }
  .set-password-form-container .sign-in-button-col {
    --ion-grid-column-padding: 0; }

.sign-up-set-password-container {
  margin: 0 5px; }
  .sign-up-set-password-container .text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 20px; }
    .sign-up-set-password-container .text-container .subheader-container {
      margin-bottom: 8px; }
    .sign-up-set-password-container .text-container .header-container {
      margin-bottom: 10px; }
    .sign-up-set-password-container .text-container .paragraph-container {
      margin-bottom: 25px; }
    .sign-up-set-password-container .text-container p,
    .sign-up-set-password-container .text-container h3,
    .sign-up-set-password-container .text-container h4 {
      margin: 0; }

.otp-input {
  width: 100%;
  caret-color: transparent;
  text-align: center;
  font-size: 30px;
  border: 0px;
  border-radius: 0px !important;
  border-bottom: 2px solid black !important; }
  .otp-input .has-focus {
    border-bottom: 2px solid #2d95f4; }

.error {
  border-bottom: 2px solid #ed3138; }

.otp-form {
  width: 100%;
  display: flex;
  justify-content: space-between; }

.otp-input-container {
  width: 50px; }

.sign-up-page-verify-core-layout .sign-up-verify-page-container {
  margin: 0 22px; }
  .sign-up-page-verify-core-layout .sign-up-verify-page-container h4 {
    margin: 0;
    padding: 0; }
  .sign-up-page-verify-core-layout .sign-up-verify-page-container h3 {
    margin: 0;
    padding: 0; }
  .sign-up-page-verify-core-layout .sign-up-verify-page-container .text-container {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .sign-up-page-verify-core-layout .sign-up-verify-page-container .subheader-container {
    margin-top: 20px;
    margin-bottom: 16px; }
  .sign-up-page-verify-core-layout .sign-up-verify-page-container .header-container {
    margin-bottom: 2px; }
  .sign-up-page-verify-core-layout .sign-up-verify-page-container .paragraph-container {
    margin-bottom: 6px;
    text-align: center; }
  .sign-up-page-verify-core-layout .sign-up-verify-page-container .phone-number-container {
    margin-bottom: 20px; }
  .sign-up-page-verify-core-layout .sign-up-verify-page-container .otp-form-container {
    margin-bottom: 8px; }

.timer {
  padding: 0; }
  .timer .timer-row {
    padding: 0;
    color: #ed3138;
    font-size: 14px; }
  .timer .reset-row {
    padding: 0;
    color: '#222222';
    font-size: 14px; }
    .timer .reset-row .pre {
      margin-right: 6px;
      margin-bottom: 8px; }
    .timer .reset-row .after {
      font-weight: 500;
      text-decoration: underline;
      color: #2d95f4; }
    .timer .reset-row .disabled {
      opacity: 0.65; }

.additional-info-box-container {
  background-color: #22222280;
  border-radius: 4px;
  color: white; }
  .additional-info-box-container .additional-info-content {
    margin: 0 12px; }

.sign-up-referral-complete-container {
  height: 100%; }
  .sign-up-referral-complete-container .sign-up-referral-complete-content-container {
    background: white;
    padding-top: 100px;
    height: 100%; }
  .sign-up-referral-complete-container .sign-up-referral-complete-success-image-row {
    justify-content: center;
    margin-bottom: 32px; }
    .sign-up-referral-complete-container .sign-up-referral-complete-success-image-row .sign-up-referral-complete-pending-approve-img {
      max-width: 60px;
      max-height: 60px; }
  .sign-up-referral-complete-container .sign-up-referral-complete-success-text-row {
    justify-content: center;
    margin-left: 16px;
    margin-right: 16px; }
    .sign-up-referral-complete-container .sign-up-referral-complete-success-text-row .sign-up-referral-complete-success-text {
      color: #47525D;
      font-size: 20px;
      font-weight: 500;
      text-align: center; }
  .sign-up-referral-complete-container .sign-up-referral-complete-success-text-row-name {
    justify-content: center;
    margin-left: 16px;
    margin-right: 16px; }
  .sign-up-referral-complete-container .sign-up-referral-complete-success-name-text {
    color: #47525D;
    font-weight: 500;
    font-size: 20px;
    text-align: center; }
  .sign-up-referral-complete-container .sign-up-referral-complete-name-text {
    color: #47525D;
    font-size: 20px;
    font-weight: 500;
    margin-top: 0px; }
  .sign-up-referral-complete-container .sign-up-referral-complete-content-row {
    margin-top: 26px;
    margin-left: 26px;
    margin-right: 26px;
    margin-bottom: 16px;
    justify-content: center; }
    .sign-up-referral-complete-container .sign-up-referral-complete-content-row .sign-up-referral-complete-content-text {
      font-size: 16px; }
  .sign-up-referral-complete-container .sign-up-referral-complete-social-row {
    justify-content: center;
    margin-bottom: 100px; }
    .sign-up-referral-complete-container .sign-up-referral-complete-social-row .sign-up-referral-complete-line-logo-container {
      background: #00C300;
      max-height: 32px;
      max-width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      margin-right: 7px; }
      .sign-up-referral-complete-container .sign-up-referral-complete-social-row .sign-up-referral-complete-line-logo-container img {
        width: 100%;
        height: 100%; }
    .sign-up-referral-complete-container .sign-up-referral-complete-social-row .sign-up-referral-complete-line-text {
      font-size: 16px;
      color: #47525D; }
  .sign-up-referral-complete-container .sign-up-referral-download-app-text-row {
    justify-content: center;
    margin-bottom: 16px; }
    .sign-up-referral-complete-container .sign-up-referral-download-app-text-row .sign-up-referral-download-app-text {
      font-size: 16px; }
  .sign-up-referral-complete-container .sign-up-referral-download-container {
    width: 100%;
    justify-content: center; }
  .sign-up-referral-complete-container .sign-up-referral-download-app {
    height: 40px;
    width: 134px; }

.sign-up-referral-complete-line-link {
  text-decoration: none; }

.abc {
  justify-content: center; }

.sign-up-referral-form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white; }

.line-login-button {
  --background: #00C300;
  --background-activated: #00B300;
  --background-focused: #00E000;
  --background-hover: #00E000;
  --padding-start: 0px;
  --padding-end: 30px;
  height: 40px;
  margin-bottom: 16px; }
  .line-login-button .line-login-button__icon {
    height: 100%;
    border-right: 1px solid #00B300; }
  .line-login-button a {
    color: white;
    text-decoration: none; }
  .line-login-button a :visited {
    color: white;
    text-decoration: none; }
  .line-login-button:hover .line-login-button__icon {
    border-right: 1px solid #00C900; }
  .line-login-button .line-login-button__text {
    width: 100%; }

.facebook-sign-in-button {
  --background: #4968AD;
  height: 40px;
  text-align: center;
  margin-bottom: 16px;
  --padding-end: 30px; }
  .facebook-sign-in-button .facebook-sign-in-button__icon {
    font-size: 32px; }
  .facebook-sign-in-button .facebook-sign-in-button__text {
    width: 100%; }
  .facebook-sign-in-button a {
    color: white;
    text-decoration: none; }
  .facebook-sign-in-button a :visited {
    color: white;
    text-decoration: none; }

.sign-up-referral-container {
  margin-left: 16px;
  margin-right: 16px;
  background: white;
  height: 100%; }
  .sign-up-referral-container .sign-up-referral-content-container {
    background: white;
    height: 100%; }
    .sign-up-referral-container .sign-up-referral-content-container .sign-up-referral-header-row {
      margin-bottom: 24px;
      justify-content: center; }
    .sign-up-referral-container .sign-up-referral-content-container .sign-up-referral-header-text-row {
      justify-content: center;
      margin-bottom: 16px; }
      .sign-up-referral-container .sign-up-referral-content-container .sign-up-referral-header-text-row .sign-up-referral-header-text {
        font-size: 20px;
        font-weight: 500;
        line-height: 1.5;
        text-align: center; }
    .sign-up-referral-container .sign-up-referral-content-container .sign-up-referral-content-row {
      margin-bottom: 24px;
      justify-content: center; }
      .sign-up-referral-container .sign-up-referral-content-container .sign-up-referral-content-row .sign-up-referral-content-text {
        font-size: 16px; }
    .sign-up-referral-container .sign-up-referral-content-container .sign-up-referral-sign-in-with-email-row {
      justify-content: center;
      margin-bottom: 13px; }
      .sign-up-referral-container .sign-up-referral-content-container .sign-up-referral-sign-in-with-email-row .line {
        flex: 10% 1;
        border-bottom: 1px solid #989aa2;
        -webkit-transform: translateY(-45%);
                transform: translateY(-45%); }
      .sign-up-referral-container .sign-up-referral-content-container .sign-up-referral-sign-in-with-email-row .sign-up-referral-sign-in-with-email-text {
        font-size: 13px;
        width: 100;
        color: #47525D;
        margin-left: 5px;
        margin-right: 5px; }

.tenant-form-container {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f7f7f7; }

.switch-tenant-page {
  padding: 24px 16px 16px 16px; }
  .switch-tenant-page .switch-tenant-button {
    margin-bottom: 16px; }
  .switch-tenant-page .button-divider {
    display: flex;
    padding: 0 8px;
    margin: 16px 0; }
    .switch-tenant-page .button-divider .text {
      margin: 0 10px; }
    .switch-tenant-page .button-divider .line {
      flex: 30% 1;
      border-bottom: 1px solid black;
      -webkit-transform: translateY(-45%);
              transform: translateY(-45%); }

.team-provider-card {
  font-size: medium;
  border: solid 1px #5A8AC3;
  border-radius: 5px;
  padding: 10px 5px;
  margin-bottom: 3px;
  display: flex;
  align-items: flex-start; }
  .team-provider-card ion-avatar {
    height: 50px;
    width: 50px; }
  .team-provider-card .provider-detail {
    display: flex;
    flex-direction: column; }
    .team-provider-card .provider-detail .title {
      display: flex;
      justify-content: space-between; }
    .team-provider-card .provider-detail .distance {
      display: flex;
      padding-top: 5px;
      font-size: small; }
      .team-provider-card .provider-detail .distance ion-icon {
        font-size: large;
        margin-right: 5px; }
  .team-provider-card .rating {
    margin-bottom: auto; }

.assign-provider-page ion-searchbar {
  --box-shadow: none;
  --border-radius: 25px;
  padding-top: 25px;
  --border-width: 0;
  margin-bottom: 10px; }
  .assign-provider-page ion-searchbar .searchbar-input-container {
    border: solid 1px var(--ion-color-primary);
    border-radius: 25px; }
    .assign-provider-page ion-searchbar .searchbar-input-container input.searchbar-input {
      -webkit-padding-start: 15px !important;
              padding-inline-start: 15px !important;
      background-color: white; }
    .assign-provider-page ion-searchbar .searchbar-input-container ion-icon {
      right: 16px !important;
      left: auto !important; }
  .assign-provider-page ion-searchbar .searchbar-clear-button.sc-ion-searchbar-md {
    right: 35px; }

.assign-provider-page .header-searchbar {
  --background-color: white; }

.assign-provider-page .assign-provider-page___list .assign-provider-page___item {
  font-size: medium;
  border: solid 1px #5A8AC3;
  border-radius: 5px;
  padding: 10px 5px;
  margin-bottom: 3px;
  display: flex;
  align-items: flex-start; }
  .assign-provider-page .assign-provider-page___list .assign-provider-page___item .provider-detail {
    display: flex;
    flex-direction: column; }
    .assign-provider-page .assign-provider-page___list .assign-provider-page___item .provider-detail .title {
      display: flex;
      justify-content: space-between; }
    .assign-provider-page .assign-provider-page___list .assign-provider-page___item .provider-detail .distance {
      display: flex;
      padding-top: 5px;
      font-size: small; }
      .assign-provider-page .assign-provider-page___list .assign-provider-page___item .provider-detail .distance ion-icon {
        font-size: large;
        margin-right: 5px; }
  .assign-provider-page .assign-provider-page___list .assign-provider-page___item .rating {
    margin-bottom: auto; }

.team-job-detail-page ion-header.sub-header ion-item {
  --inner-padding-top: 10px;
  --inner-padding-bottom: 10px; }

.team-job-detail-page ion-header.sub-header ion-label label.job-number {
  font-size: 20px;
  font-weight: 500; }
  .team-job-detail-page ion-header.sub-header ion-label label.job-number ion-chip.pending_start {
    background: #f1701b;
    color: white; }
  .team-job-detail-page ion-header.sub-header ion-label label.job-number ion-chip.pending_complete {
    background: #18abcc;
    color: white; }
  .team-job-detail-page ion-header.sub-header ion-label label.job-number ion-chip.completed {
    background: #21ba45;
    color: white; }
  .team-job-detail-page ion-header.sub-header ion-label label.job-number ion-chip.cancel {
    background: #d0021b;
    color: white; }
  .team-job-detail-page ion-header.sub-header ion-label label.job-number ion-chip.pending_assign {
    background: #1b4170;
    color: white; }

.team-job-detail-page ion-header.sub-header ion-label .location,
.team-job-detail-page ion-header.sub-header ion-label .cash {
  font-size: 13px; }
  .team-job-detail-page ion-header.sub-header ion-label .location ion-icon,
  .team-job-detail-page ion-header.sub-header ion-label .location ion-text,
  .team-job-detail-page ion-header.sub-header ion-label .cash ion-icon,
  .team-job-detail-page ion-header.sub-header ion-label .cash ion-text {
    color: #1b4170; }

.team-job-detail-page ion-header.sub-header ion-label .location ion-icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  font-size: 20px; }

.team-job-detail-page ion-header.sub-header ion-label .cash {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .team-job-detail-page ion-header.sub-header ion-label .cash ion-icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    font-size: 20px; }
  .team-job-detail-page ion-header.sub-header ion-label .cash .amount {
    float: right;
    font-weight: 500;
    font-size: 24px; }

.team-job-detail-page ion-content.content ion-grid {
  -webkit-padding-start: 15px;
          padding-inline-start: 15px;
  -webkit-padding-end: 15px;
          padding-inline-end: 15px; }

.team-job-detail-page ion-content.content ion-col.start-date {
  display: flex; }

.team-job-detail-page ion-content.content ion-col.start-time ion-item {
  --inner-padding-end: 0; }

.team-job-detail-page ion-content.content ion-row.order ion-item {
  margin-bottom: 10px;
  --inner-padding-end: 0; }

.team-job-detail-page ion-content.content ion-row.order ion-label.order {
  margin-bottom: 10px; }

.team-job-detail-page ion-content.content ion-row.comment .mock-textarea {
  border: 1px solid #cecece66;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
  height: 166px;
  color: #dee0e2; }

.team-job-detail-page ion-content.content ion-row.comment ion-textarea {
  border: 1px solid #cecece66;
  border-radius: 5px;
  --padding-start: 10px;
  margin-bottom: 10px; }

.team-job-detail-page .team-job-detail-page___footer ion-toolbar {
  padding: 15px; }

.team-people-page .team-people-page___sub-header ion-avatar {
  width: 72px;
  height: 72px; }

.provider-detail-page .provider-detail-page___sub-header .provider-profile {
  --padding-top: 10px;
  --padding-bottom: 10px; }
  .provider-detail-page .provider-detail-page___sub-header .provider-profile ion-avatar {
    height: 80px;
    width: 80px; }
  .provider-detail-page .provider-detail-page___sub-header .provider-profile .provider-profile___content ion-label.name {
    font-weight: 500; }
  .provider-detail-page .provider-detail-page___sub-header .provider-profile .provider-profile___content .distance, .provider-detail-page .provider-detail-page___sub-header .provider-profile .provider-profile___content .team, .provider-detail-page .provider-detail-page___sub-header .provider-profile .provider-profile___content .rating {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    color: #1B4170; }
    .provider-detail-page .provider-detail-page___sub-header .provider-profile .provider-profile___content .distance ion-icon, .provider-detail-page .provider-detail-page___sub-header .provider-profile .provider-profile___content .team ion-icon, .provider-detail-page .provider-detail-page___sub-header .provider-profile .provider-profile___content .rating ion-icon {
      margin-right: 5px;
      font-size: 16px; }

.provider-detail-page .provider-detail-page___sub-header ion-item.provider-detail {
  --inner-padding-top: 10px;
  --inner-padding-bottom: 10px; }
  .provider-detail-page .provider-detail-page___sub-header ion-item.provider-detail .completed-job-number {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
  .provider-detail-page .provider-detail-page___sub-header ion-item.provider-detail .contact-provider a {
    text-decoration: none; }
  .provider-detail-page .provider-detail-page___sub-header ion-item.provider-detail .contact-provider ion-chip {
    --background: var(--ion-color-secondary-tint);
    padding-left: 15px;
    color: var(--ion-color-secondary-contrast); }
    .provider-detail-page .provider-detail-page___sub-header ion-item.provider-detail .contact-provider ion-chip ion-icon {
      color: var(--ion-color-secondary-contrast); }

.provider-detail-page .provider-detail-page___content ion-list ion-item.job {
  --inner-padding-top: 15px;
  --inner-padding-bottom: 15px; }
  .provider-detail-page .provider-detail-page___content ion-list ion-item.job ion-chip {
    font-size: 10px;
    font-weight: 500; }
    .provider-detail-page .provider-detail-page___content ion-list ion-item.job ion-chip.pending_start {
      background: #F1701B;
      color: white; }
    .provider-detail-page .provider-detail-page___content ion-list ion-item.job ion-chip.pending_completion {
      background: #18ABCC;
      color: white; }
    .provider-detail-page .provider-detail-page___content ion-list ion-item.job ion-chip.completed {
      background: #21BA45;
      color: white; }
    .provider-detail-page .provider-detail-page___content ion-list ion-item.job ion-chip.cancel {
      background: #D0021B;
      color: white; }

.provider-detail-page .provider-detail-page___footer ion-toolbar {
  padding: 15px; }

.provider-detail-page ion-backdrop {
  opacity: 0.5;
  z-index: 10; }

ion-grid.team-job-card {
  font-size: medium;
  padding-left: 0px; }
  ion-grid.team-job-card ion-col {
    padding-left: 0px; }
  ion-grid.team-job-card .team-job-card__first-row ion-col.title {
    display: flex;
    padding-left: 0px; }
    ion-grid.team-job-card .team-job-card__first-row ion-col.title ion-thumbnail {
      margin-right: 8px; }
    ion-grid.team-job-card .team-job-card__first-row ion-col.title ion-item {
      --inner-padding-end: 0px;
      width: 100%; }
    ion-grid.team-job-card .team-job-card__first-row ion-col.title ion-chip {
      margin: 0; }
  ion-grid.team-job-card .team-job-card__first-row ion-col.status {
    display: flex; }
    ion-grid.team-job-card .team-job-card__first-row ion-col.status ion-button {
      --border-radius: 30px; }
    ion-grid.team-job-card .team-job-card__first-row ion-col.status ion-chip.pending_start {
      background: #F1701B;
      color: white; }
    ion-grid.team-job-card .team-job-card__first-row ion-col.status ion-chip.pending_complete {
      background: #18ABCC;
      color: white; }
    ion-grid.team-job-card .team-job-card__first-row ion-col.status ion-chip.completed {
      background: #21BA45;
      color: white; }
    ion-grid.team-job-card .team-job-card__first-row ion-col.status ion-chip.cancel {
      background: #D0021B;
      color: white; }
    ion-grid.team-job-card .team-job-card__first-row ion-col.status ion-chip.pending_assign {
      background: #1B4170;
      color: white; }
  ion-grid.team-job-card .team-job-card__second-row .job-start-time {
    display: flex;
    align-items: center; }
    ion-grid.team-job-card .team-job-card__second-row .job-start-time ion-icon {
      margin-right: 5px;
      min-width: 16px; }
  ion-grid.team-job-card .team-job-card__second-row .price {
    display: flex;
    justify-content: flex-end;
    font-size: x-large;
    font-weight: 500; }
  ion-grid.team-job-card .team-job-card__third-row .order {
    display: flex;
    justify-content: space-between; }
  ion-grid.team-job-card .team-job-card__fourth-row .location, ion-grid.team-job-card .team-job-card__fourth-row .provider {
    display: flex;
    align-items: center; }
    ion-grid.team-job-card .team-job-card__fourth-row .location ion-icon, ion-grid.team-job-card .team-job-card__fourth-row .provider ion-icon {
      font-size: 20px;
      margin-right: 5px;
      min-width: 20px; }
    ion-grid.team-job-card .team-job-card__fourth-row .location .display_name, ion-grid.team-job-card .team-job-card__fourth-row .provider .display_name {
      text-decoration: underline; }
    ion-grid.team-job-card .team-job-card__fourth-row .location .recipient, ion-grid.team-job-card .team-job-card__fourth-row .provider .recipient {
      margin-left: auto; }
    ion-grid.team-job-card .team-job-card__fourth-row .location.pending_start .recipient, ion-grid.team-job-card .team-job-card__fourth-row .provider.pending_start .recipient {
      color: #F1701B; }
    ion-grid.team-job-card .team-job-card__fourth-row .location.pending_complete .recipient, ion-grid.team-job-card .team-job-card__fourth-row .provider.pending_complete .recipient {
      color: #18ABCC; }
    ion-grid.team-job-card .team-job-card__fourth-row .location.completed .recipient, ion-grid.team-job-card .team-job-card__fourth-row .provider.completed .recipient {
      color: #21BA45; }
    ion-grid.team-job-card .team-job-card__fourth-row .location.cancel ion-icon, ion-grid.team-job-card .team-job-card__fourth-row .provider.cancel ion-icon {
      color: #D0021B; }
    ion-grid.team-job-card .team-job-card__fourth-row .location.cancel .display_name, ion-grid.team-job-card .team-job-card__fourth-row .provider.cancel .display_name {
      text-decoration: none;
      color: #D0021B; }

.accordion .accordion__header {
  font-size: medium; }
  .accordion .accordion__header .label__container {
    display: flex; }
    .accordion .accordion__header .label__container ion-label {
      margin-right: 10px; }

.accordion .accordion__content {
  overflow-y: scroll;
  max-height: auto; }
  .accordion .accordion__content.expanded {
    max-height: auto; }
  .accordion .accordion__content.closed {
    max-height: 0px; }

.work-page-header {
  position: relative;
  background-color: var(--ion-color-primary); }
  .work-page-header ion-grid ion-row:first-of-type ion-col {
    padding: 25px 15px 0px; }
  .work-page-header ion-grid ion-row.searchbar-row ion-searchbar {
    padding-top: 0;
    --border-radius: 25px; }
    .work-page-header ion-grid ion-row.searchbar-row ion-searchbar ion-icon {
      right: 16px !important;
      left: auto !important; }
    .work-page-header ion-grid ion-row.searchbar-row ion-searchbar input {
      -webkit-padding-start: 20px;
              padding-inline-start: 20px; }
    .work-page-header ion-grid ion-row.searchbar-row ion-searchbar .searchbar-clear-button.sc-ion-searchbar-md {
      right: 35px; }
  .work-page-header .header-label {
    font-size: 25px;
    font-weight: 500;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .work-page-header .avatar-container {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    width: 60px; }
    .work-page-header .avatar-container ion-avatar {
      height: 57px;
      width: 57px; }

.work-page-content {
  max-height: calc(100% - 187.3px); }

.verify-complete-grid {
  margin: 0 20px; }
  .verify-complete-grid .verify-complete-logo-row {
    margin-top: 12px; }
  .verify-complete-grid .verify-complete-header-row {
    margin-bottom: 8px; }
  .verify-complete-grid .verify-complete-lower {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .verify-complete-grid .verify-complete-subheader-row {
    margin-bottom: 8px; }
  .verify-complete-grid .verify-complete-paragraph-row {
    margin-bottom: 50px; }
  .verify-complete-grid .verify-complete-avatar-row {
    margin-bottom: 20px; }
  .verify-complete-grid .verify-complete-paragraph-2-row {
    margin-bottom: 12px; }

.avatar-card .avatar-name-text {
  font-size: 13pt;
  margin-left: 12px; }

.avatar-card .avatar-img-container {
  height: 50px;
  width: 50px; }

.verify-info-grid {
  margin: 0 20px; }
  .verify-info-grid p,
  .verify-info-grid h3 {
    margin: 0; }
  .verify-info-grid .verify-info-logo {
    margin-top: 18px;
    margin-bottom: 12px; }
  .verify-info-grid .verify-info-header {
    margin-bottom: 12px; }
  .verify-info-grid .verify-info-subheader {
    margin-bottom: 16px;
    margin: 0; }
  .verify-info-grid .verify-info-paragraph {
    margin-bottom: 30px; }
  .verify-info-grid .verify-info-picture-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px; }
    .verify-info-grid .verify-info-picture-row .avatar-row {
      margin-bottom: 16px; }
    .verify-info-grid .verify-info-picture-row .verify-info-picture {
      height: 150px;
      width: 170px; }
  .verify-info-grid .verify-info-form {
    transition: width 2s, height 2s, -webkit-transform 2s;
    transition: width 2s, height 2s, transform 2s;
    transition: width 2s, height 2s, transform 2s, -webkit-transform 2s; }
  .verify-info-grid .verify-info-form-hidden {
    display: none; }

.verify-grid {
  margin: 0 20px; }
  .verify-grid h4 {
    margin: 0; }

.verify-logo-row {
  margin-top: 12px;
  margin-bottom: 16px; }

.verify-subheader-row {
  margin-bottom: 12px; }

.verify-error {
  color: #ed3138; }

.verify-phone-number-row {
  margin-bottom: 24px; }

.verify-phone-number-form-row {
  margin-bottom: 12px; }

.verify-phone-number-error-row {
  margin-bottom: 6px; }

