.sign-up-set-password-container {
  margin: 0 5px;
  .text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 20px;

    .subheader-container {
      margin-bottom: 8px;
    }

    .header-container {
      margin-bottom: 10px;
    }

    .paragraph-container {
      margin-bottom: 25px;
    }
    p,
    h3,
    h4 {
      margin: 0;
    }
  }
}
