// Text Sizes
$title1: 28px;
$title-md: 24px;
$title2: 20px;
$body: 17px;
$subtitle: 15px;
$caption: 13px;

// Text Weight
$medium: 500;
$regular: 400;
$light: 300;
