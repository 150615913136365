.calendar-card {
  margin: 0;
  margin-bottom: 8px;
  background-color: #E9EFF5;
  box-shadow: none;
}

.card-header-container {
  padding: 8px;
}

.center-text-subtitle {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 400;
}

.center-text-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  font-weight: 500;
}

.center-text-content {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  padding: 8px 0;
}

.close-icon {
  height: 30px;
  position: absolute;
  z-index: 1;
  right: -8px;
  width: 30px;
  top: -8px;
}
