ion-row.comment {
    .mock-textarea {
      border: 1px solid #cecece66;
      border-radius: 5px;
      padding: 5px;
      margin-bottom: 10px;
      height: 166px;
      color: rgb(222, 224, 226);
    }
    ion-textarea {
      border: 1px solid #cecece66;
      border-radius: 5px;
      --padding-start: 10px;
      margin-bottom: 10px;
    }

    .comment-btn-box {
        margin-top: 8px;
        .expand-comment-text {
            text-decoration: underline;
            color: #2e95f4;
        }
    }

}
