@import 'src/theme/variables.scss';

.page-header {
  --min-height: 56px;

  .page-header__back-button {
    padding-left: 8px;

    &.withBackButton.onBack {
      ion-back-button {
        display: block;
      }
    }
  }

  .page-title {
    font-size: $title2;
    font-weight: $regular;
    padding-inline-start: 0px;
    padding-inline-end: 0px;
  }
}

.page-header-no-border {
  --min-height: 56px;
  --border-width: 0 !important;

  .page-header__back-button {
    padding-left: 8px;

    &.withBackButton.onBack {
      ion-back-button {
        display: block;
      }
    }
  }

  .page-title {
    font-size: $title2;
    font-weight: $regular;
  }
}

.page-header__seconday {
  --padding-bottom: 0px;
  --padding-end: 0px;
  --padding-start: 0px;
  --padding-top: 0px;
}
