@import 'src/theme/variables.scss';

.team-job-detail-page {
  ion-header.sub-header {
    ion-item {
      --inner-padding-top: 10px;
      --inner-padding-bottom: 10px;
    }
    ion-label {
      label.job-number {
        font-size: $title2;
        font-weight: $medium;

        ion-chip {
          &.pending_start {
            background: #f1701b;
            color: white;
          }
          &.pending_complete {
            background: #18abcc;
            color: white;
          }
          &.completed {
            background: #21ba45;
            color: white;
          }
          &.cancel {
            background: #d0021b;
            color: white;
          }
          &.pending_assign {
            background: #1b4170;
            color: white;
          }
        }
      }

      .location,
      .cash {
        font-size: $caption;

        ion-icon,
        ion-text {
          color: #1b4170;
        }
      }

      .location {
        ion-icon {
          display: inline-block;
          // vertical-align: baseline;
          vertical-align: middle;
          margin-right: 10px;

          font-size: 20px;
        }
      }

      .cash {
        display: flex;
        justify-content: space-between;
        align-items: center;
        ion-icon {
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;

          font-size: 20px;
        }

        .amount {
          // margin-left: auto;
          float: right;
          font-weight: $medium;
          font-size: $title-md;
        }
      }
    }
  }

  ion-content.content {
    ion-grid {
      padding-inline-start: 15px;
      padding-inline-end: 15px;
    }

    ion-col.start-date {
      display: flex;
    }

    ion-col.start-time {
      ion-item {
        --inner-padding-end: 0;
      }
    }

    ion-row.order {
      ion-item {
        margin-bottom: 10px;
        --inner-padding-end: 0;

        ion-text[slot='end'] {
          // margin-bottom: auto;
        }
      }

      ion-label.order {
        margin-bottom: 10px;
      }
    }

    ion-row.comment {
      .mock-textarea {
        border: 1px solid #cecece66;
        border-radius: 5px;
        padding: 5px;
        margin-bottom: 10px;
        height: 166px;
        color: rgb(222, 224, 226);
      }
      ion-textarea {
        border: 1px solid #cecece66;
        border-radius: 5px;
        --padding-start: 10px;
        margin-bottom: 10px;
      }
    }
  }

  .team-job-detail-page___footer {
    ion-toolbar {
      padding: 15px;
    }
  }
}
