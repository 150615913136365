.bank-account-form-container {
  padding: 16px;
  background-color: white;

  input[type=tel] {
    &:focus {
      outline: none;
    }
  }

  .annotation {
    margin: 0;
  }
}
