.address-form-container {
  padding: 16px;
  background-color: white;
  height: 100%;

  .update-current-location-btn {
    background-color: #1B4170;
    color: #FFFFFF;
    padding: 8px 14px;
    border-radius: 8px;
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 195px;
  }

  .update-home-address-icon {
    font-size: 30px;
    padding-right: 8px;
  }

  .update-current-location-map-container {
    position: relative;
    box-shadow: 0 1px 3px 0 #b6b6b6;
  }
}
